// Core components
import {Component, Input} from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    animations: [
        trigger('fade', [
            state('in', style({
                opacity: '1'
            })),
            transition(':enter', [
                style({opacity: 1}),
                animate(150 )
            ]),
            transition(':leave',
                animate(150, style({opacity: 0})))
        ]),
    ]
})

export class LoaderComponent {

    @Input() public isLoading: boolean;

    constructor() {
    }

}
