import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ENDPOINTS } from '@constants/endpoints.constants';
import { Recipe } from '@core/models/recipe';
import { Comment } from '@models/comment';
import { GenericAPI } from './generic-api.service';
import { environment } from '@env';
import { Observable, from, throwError, of } from 'rxjs';
import { ApplicationDatabaseService } from 'providers/db/application-database.service';
import { flatMap, share, finalize, catchError } from 'rxjs/operators';
import { LoadingService } from '@services/loading.service';
import {PersonalNotes} from "@models/notes";

@Injectable({
    providedIn: 'root'
})

export class RecipesApiService extends GenericAPI {

    constructor(
        private http: HttpClient,
        public applicationDatabaseService: ApplicationDatabaseService,
        private loadingService: LoadingService
    ) {
        super(applicationDatabaseService);
    }

    public getRecipe(id: number, token?: string, meal?: string, menu_id?: string, num_dinners?: number): Observable<Recipe> {
        this.loadingService.openLoading();
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {
                if (token) {
                    defaultHeaders = defaultHeaders
                        .set('token', token);
                }

                let params = new HttpParams()

                if (meal && menu_id) {
                    params = params
                        .set('meal', meal)
                        .set('menu_id', menu_id)
                } else if (num_dinners) {
                    params = params.set('num_diners', num_dinners.toString());
                }

                return this.http.get<Recipe>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.RECIPE_DETAIL}/${id}`,
                    {
                        headers: defaultHeaders,
                        params
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public getRecipesBy(searchBy: string, filterBy: string, orderBy: string, isFavFiltered: boolean, duration: string, offset: string, limit: string, vegan: string): Observable<Recipe[]> {
        this.loadingService.openLoading(true);
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                const params = new HttpParams()
                    .set('search', searchBy)
                    .set('mealType', filterBy)
                    .set('is_fav', isFavFiltered.toString())
                    .set('duration', duration)
                    .set('order', orderBy)
                    .set('offset', offset)
                    .set('limit', limit)
                    .set('vegan', vegan);

                return this.http.get<Recipe[]>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.RECIPES_BY}`,
                    {
                        params,
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public createRating( recipeId: number, rate: number ): Observable<any> {
        this.loadingService.openLoading();

        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.RECIPES}/${recipeId}/ratings/${rate}`,
                    undefined,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );

    }

    public sendComment( recipeId: number, comment: string ): Observable<any> {
        this.loadingService.openLoading();

        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                const body = new FormData();
                body.append('comment', comment);

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.RECIPES}/${recipeId}/comments`,
                    body,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );

    }

    public getPersonalNotes( recipeId: number): Observable<PersonalNotes> {
        this.loadingService.openLoading();
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {
                return this.http.get<PersonalNotes>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.RECIPES}/${recipeId}/notes`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }


    public sendPersonalNotes( recipeId: number, comment: string ): Observable<any> {
        this.loadingService.openLoading();

        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                const body = new FormData();
                body.append('comment', comment);

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.RECIPES}/${recipeId}/notes`,
                    body,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );

    }

    public addToFavorite( recipeId: number ): Observable<any> {
        this.loadingService.openLoading();

        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.RECIPES}/${recipeId}/favs`,
                    undefined,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );

    }

    public removeFromFavorite( recipeId: number ): Observable<any> {
        this.loadingService.openLoading();

        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.delete(
                    `${environment.rest.baseUrl}/${ENDPOINTS.RECIPES}/${recipeId}/favs`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );

    }


    public changeRecipeMenu(menuId: string, meal: string, recipeId: number, behaviour: string) {
        this.loadingService.openLoading();
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded');

                const body = new HttpParams()
                    .set('meal', meal)
                    .set('menu_id', menuId.toString())
                    .set('behaviour', behaviour.toString())
                    .set('recipe_id', recipeId.toString());

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.RECIPE_REPLACEMENT}`,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }


    public changeNumDinners(value: string, menuId: string, meal: string, recipeId: number, behaviour: string) {
        this.loadingService.openLoading();
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded');

                const body = new HttpParams()
                    .set('meal', meal)
                    .set('menu_id', menuId.toString())
                    .set('behaviour', behaviour.toString())
                    .set('num_diners', value)
                    .set('recipe_id', recipeId.toString());

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.RECIPE_REPLACEMENT}`,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }
}
