export const environment = {
  production: false,
  rest: {
    baseUrl: 'https://api-devel.venusanz-tech.com/api',
    secret: 'Ie9urGHtGUi2iPphY5YXfeYICqUamvEW',
    apiKey: 'VENUSANZ.INT.UPTAL4HSJ5XPZSB9569204',
  },
  database: {
    secret: 'ytxFpRugCA'
  },
  urlWordpress: 'https://clubmenusaludable.com/login'
};
