import { Allergen } from './allergen';
import { Subscription } from './subscriptionStatus';


export interface User {
    allergen: Allergen;
    avatar: string | ArrayBuffer;
    breakfast_included: boolean;
    email: string;
    first_name: string;
    id: number;
    last4: number;
    last_name: string;
    profile_completed: boolean;
    snack_included: boolean;
    stripe_customer_id: string;
    type: User.USER_ROLE;
    num_diners: number;
    subscription: Subscription;
    is_old: boolean;
    vegan: boolean;
}

export namespace User {
    export enum USER_ROLE {
        REGULAR = 'REGULAR',
        ADMIN = 'ADMIN',
        AUTHOR = 'AUTHOR'
    }
}
