// Core dependencies
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeESP from '@angular/common/locales/es';

// Routing
import { AppRoutingModule } from '@app/app-routing.module';

// Main Component
import { AppComponent } from '@app/app.component';

// Modules
import { ShellModule } from '@shell/shell.module';

// Pages
import { LoginModule } from '@pages/login/login.module';
import { WelcomeModule } from '@pages/welcome/welcome.module';
import { WeeklyMenuModule } from '@pages/weekly-menu/weekly-menu.module';

// Components
import { SnackBarGeneralErrorModule } from '@components/snack-bar-general-error/snack-bar-general-error.module';
import { LoaderModule } from '@components/loader/loader.module';
import { ProgressBarModule } from '@components/progress-bar/progress-bar.module';
import { NoPlanDialogModule } from '@components/no-plan-dialog/no-plan-dialog.module';

// Interceptors
import { ApiInterceptor } from '@interceptors/api.interceptor';

// Constants
import { CUSTOM_BREAKPOINTS } from '@constants/customBreakpoints.constants';
import { AuthService } from '@services/auth.service';
import { CoreModule } from '@core/core.module';

// Services
import { SnackBarService } from '@services/snack-bar.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env';
import { OnboardingDialogModule } from '@components/onboarding-dialog/onboarding-dialog.module';
import { PausedPlanDialogModule } from '@components/paused-plan-dialog/paused-plan-dialog.module';


registerLocaleData(localeESP);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CoreModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ShellModule,
        LoginModule,
        WelcomeModule,
        WeeklyMenuModule,
        SnackBarGeneralErrorModule,
        NoPlanDialogModule,
        PausedPlanDialogModule,
        OnboardingDialogModule,
        FlexLayoutModule.withConfig({ disableDefaultBps: true }, CUSTOM_BREAKPOINTS),
        LoaderModule,
        ProgressBarModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        AppRoutingModule // must be imported as the last module as it contains the fallback route
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
            deps: [AuthService, SnackBarService]
        },
        {
            provide: LOCALE_ID,
            useValue: 'es-ES'
        }
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
