// Core components
import {Component, OnInit} from '@angular/core';

// Constants
import {NO_CONTENT} from '@constants/no-content.constants';

@Component({
    selector: 'app-no-content',
    templateUrl: './no-content.component.html',
    styleUrls: ['./no-content.component.scss']
})

export class NoContentComponent implements OnInit {

    public noContentIcons: string[];
    public noContentIcon: string;

    constructor() {
    }

    public ngOnInit() {
        this.noContentIcons = NO_CONTENT;
        this.noContentIcon = this.getRandomIcon();
    }

    public getRandomIcon() {
        return this.noContentIcons[Math.floor(Math.random() * this.noContentIcons.length)];
    }

}
