import { Injectable } from '@angular/core';
import { RecipesApiService } from '@api/recipes-api.service';
import { Recipe } from '@core/models/recipe';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Ingredient } from '@core/models/ingredient';
import { DomSanitizer } from '@angular/platform-browser';
import {PersonalNotes} from "@models/notes";


@Injectable({ providedIn: 'root' })
export class RecipesService {

    constructor(
        private recipesAPIService: RecipesApiService,
        private _sanitizer: DomSanitizer
    ) { }

    public async getRecipe(id: number, token?: string, meal?: string, menu_id?: string, num_dinners?: number): Promise<Recipe> {
        return this.recipesAPIService.getRecipe(id, token, meal, menu_id, num_dinners).pipe(
            map(recipe => {
                const { is_breakfast, is_lunch, is_snack, is_dinner } = recipe;
                recipe.typeOfMeal = { is_breakfast, is_lunch, is_snack, is_dinner };

                for (const [index, ingredient] of recipe.ingredients_main_preparation.entries()) {
                    ingredient.order = index;
                    this.recursiveIngredientQuantity(ingredient);
                }

                recipe.ingredients_main_preparation = this.groupByIngredients(recipe.ingredients_main_preparation);

                for (const [index, ingredient] of recipe.ingredients_previous_preparation.entries()) {
                    ingredient.order = index;
                    this.recursiveIngredientQuantity(ingredient);
                }

                recipe.ingredients_previous_preparation = this.groupByIngredients(recipe.ingredients_previous_preparation);

                if (recipe.image) {
                    recipe.image = this._sanitizer.bypassSecurityTrustStyle(`url(${recipe.image})`);
                } else {
                    recipe.image = this._sanitizer.bypassSecurityTrustStyle(`url('assets/images/png/recipe-placeholder.png')`);
                }
                return recipe;
            })
        ).toPromise();
    }

    public groupByIngredients(ingredeints) {
        const auxIngredeints = [];
        const groupBy = (<any>window)._.groupBy(ingredeints, (ingredient: Ingredient) => {
            return ingredient.group;
        })

        const keys = (<any>window)._.keys(groupBy);

        keys.forEach(key => {
            if (key && key !== 'null') {
                const order = groupBy[key].sort((a, b) => a.order - b.order)[0].order;
                const ingredient = {
                    name: key,
                    sub_ingredients: groupBy[key],
                    type: 'COMPOUND',
                    order: order
                }
                auxIngredeints.push(ingredient);
                auxIngredeints.sort((a, b) => a.order - b.order);
            } else {
                groupBy[key].forEach(ingredient => {
                    auxIngredeints.push(ingredient);
                    auxIngredeints.sort((a, b) => a.order - b.order);
                });
            }
        });

        return auxIngredeints;
    }

    private recursiveIngredientQuantity(ingredient: Ingredient, factor = 1) {
        if (ingredient.type === Ingredient.type.SIMPLE) {
            return ingredient;
        } else {
            if (ingredient.type === Ingredient.type.COMPOUND) {
                if (ingredient.sub_ingredients) {
                    for (const subingredient of ingredient.sub_ingredients) {
                        this.recursiveIngredientQuantity(subingredient, ingredient.qty);
                    }
                } else {
                    ingredient.sub_ingredients = [];
                    return ingredient;
                }
            }
        }
    }

    public getRecipesBy(searchBy = '', filterBy = '', orderBy = '', isFavFiltered = false, duration = '', offset = '', limit = '', vegan = ''): Promise<Recipe[]> {
        return this.recipesAPIService.getRecipesBy(searchBy, filterBy, orderBy, isFavFiltered, duration, offset, limit, vegan).pipe(
            map((recipes: Recipe[]) => {
                if (recipes && recipes.length) {
                    recipes.map(recipe => {
                        if (recipe.image) {
                            recipe.image = this._sanitizer.bypassSecurityTrustResourceUrl(recipe.image);
                        } else {
                            recipe.image = this._sanitizer.bypassSecurityTrustResourceUrl('assets/images/png/recipe-placeholder.png');
                        }
                    });
                    return recipes.filter(recipe => recipe.published);
                }
                return [];
            })
        ).toPromise();
    }

    public sendPersonalNotes( recipeId: number, comment: string ) {
        return this.recipesAPIService.sendPersonalNotes(recipeId, comment);
    }

    public getPersonalNotes( recipeId: number ): Promise<PersonalNotes> {
        return this.recipesAPIService.getPersonalNotes( recipeId ).toPromise();
    }

    public createRating( recipeId: number, rate: number ) {
        return this.recipesAPIService.createRating(recipeId, rate);
    }

    public sendComment( recipeId: number, comment: string ) {
        return this.recipesAPIService.sendComment(recipeId, comment);
    }

    public addToFavorite( recipeId: number ) {
        return this.recipesAPIService.addToFavorite(recipeId).toPromise();
    }

    public removeFromFavorite( recipeId: number ) {
        return this.recipesAPIService.removeFromFavorite(recipeId).toPromise();
    }

    public changeRecipeMenu(menuId: string, meal: string, recipeId: number, behaviour) {
        return this.recipesAPIService.changeRecipeMenu(menuId, meal, recipeId, behaviour).toPromise();
    }

    public changeNumDinners(value: string, menuId: string, meal: string, recipeId: number, behaviour: string) {
        return this.recipesAPIService.changeNumDinners(value, menuId, meal, recipeId, behaviour).toPromise();
    }
}
