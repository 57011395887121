import { Injectable } from "@angular/core";
import { AllergensApiService } from '@api/allergens-api.service';
import { Observable } from 'rxjs';
import { Allergen } from '@core/models/allergen';

@Injectable({ providedIn: 'root' })
export class AllergensService {

    constructor(
        private allergensApiService: AllergensApiService
    ) { }

    public getAllergens(): Observable<Allergen[]> {
        return this.allergensApiService.getAllergens();
    }

    public getAllergen(id: number): Observable<Allergen> {
        return this.allergensApiService.getAllergen(id);
    }
}