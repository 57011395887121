import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '@env';
import { DATABASE_KEYS } from '@core/constants/storage.constants';
import { ApplicationDatabaseService } from 'providers/db/application-database.service';

@Injectable({ providedIn: 'root' })
export class GenericAPI {

    constructor(
        public applicationDatabaseService: ApplicationDatabaseService
    ) { }

    protected async getGenericHeaders(): Promise<HttpHeaders> {
        let headers = new HttpHeaders();
        const token = await this.applicationDatabaseService.get(DATABASE_KEYS.userToken).toPromise();
        if (token) {
            headers = headers
                .append('token', token);
        }
        return headers
            .append('ApiKey', environment.rest.apiKey);
    }
}