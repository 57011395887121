import { Injectable } from '@angular/core';
import { DailyMenu } from '@core/models/dailyMenu';
import { BatchElement } from '@core/models/batchElement';
import { Ingredient } from "@models/ingredient";
import { Recipe } from "@models/recipe";

import { recipeTemplate } from '@core/pdfTemplates/recipe.template';
import { weeklyMenuTemplate } from '@core/pdfTemplates/weeklyMenu.template';
import { shoppingListTemplate } from '@core/pdfTemplates/shoppingList.template';
import { batchCookingTemplate } from '@core/pdfTemplates/batchCooking.template';
import { User } from '@core/models/user';

import * as jsPDF from 'jspdf';
import { Category } from '@core/models/category';

@Injectable({ providedIn: 'root' })

export class PDFService {

    public downloadRecipe(recipe: Recipe, numDiners: number, week: string = '') {
        const doc = recipeTemplate(recipe, numDiners, week);
        // Save the PDF
        doc.save(`${recipe.title}.pdf`);
    }

    public downloadWeeklyMenu(weeklyMenu: DailyMenu[], week: string, user: User) {
        const doc = weeklyMenuTemplate(weeklyMenu, week, user);
        // Save the PDF
        doc.save(`Menu Semanal - ${week}.pdf`);
    }

    public downloadShoppingList(list: Category[], week: string, numDiners: number) {
        const doc = shoppingListTemplate(list, week, numDiners);
        // Save the PDF
        doc.save(`Lista Compras - ${week}.pdf`);
    }

    public downloadBatchCooking(ingredients: Ingredient[], batch: BatchElement[], week: string) {
        const doc = batchCookingTemplate(ingredients, batch, week);
        // Save the PDF
        doc.save(`Batch Cooking - ${week}.pdf`);
    }

    public downloadAll(recipes: Recipe[], numDiners: number, weeklyMenu: DailyMenu[], week: string, user: User, list: Category[], ingredients: Ingredient[], batch: BatchElement[]) {
        const addedRecipes = [];

        let doc = new jsPDF({
            format: 'a4'
        });
        weeklyMenuTemplate(weeklyMenu, week, user, doc);
        doc.addPage();
        shoppingListTemplate(list, week, numDiners, doc);
        doc.addPage();
        batchCookingTemplate(ingredients, batch, week, doc);
        for (const recipe of recipes) {
            const recipeId = `${recipe.id}.${numDiners}`;
            if (!addedRecipes.includes(recipeId)) {
                addedRecipes.push(recipeId);
                doc.addPage();
                recipeTemplate(recipe, numDiners, doc);
            }
        }
        // let pageCount = doc.internal.getNumberOfPages();
        // doc.deletePage(pageCount)
        doc.save(`Semana - ${week}.pdf`);
    }

}
