import { ENDPOINTS } from '@constants/endpoints.constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericAPI } from './generic-api.service';
import { environment } from '@env';
import { Observable, from, throwError } from 'rxjs';
import { ApplicationDatabaseService } from 'providers/db/application-database.service';
import { flatMap, share, catchError } from 'rxjs/operators';
import { LoadingService } from '@services/loading.service';
import { Payment } from '@core/models/payment';
import { Club } from '@core/models/club';
import {CancellationGift} from "@models/cancellationGift";
import {ProductsConfig} from "@models/productsConfig";

@Injectable({
    providedIn: 'root'
})
export class PaymentPlanApiService extends GenericAPI {

    constructor(
        private http: HttpClient,
        public applicationDatabaseService: ApplicationDatabaseService,
        private loadingService: LoadingService
    ) {
        super(applicationDatabaseService);
    }

    public getProductsConfig(stripeProductId: string) {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                let endpoint = `${environment.rest.baseUrl}/${ENDPOINTS.PRODUCTS_CONFIG}`;
                if (stripeProductId) {
                  endpoint = endpoint + `?stripe_product_id=${stripeProductId}`;
                }

                return this.http.get<ProductsConfig>(
                    endpoint,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public getPaymentPlans(stripeProductId: string): Observable<Payment[]> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {
                let endpoint = `${environment.rest.baseUrl}/${ENDPOINTS.PAYMENT_PLANS}`;
                if (stripeProductId) {
                  endpoint = endpoint + `?stripe_product_id=${stripeProductId}`;
                }
                return this.http.get<Payment[]>(
                    endpoint,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public getTestimonials(): Observable<any> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.get<Club>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.TESTIMONIALS}`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public getCancellationGifts(planRole: string): Observable<CancellationGift[]> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                let params = new HttpParams();
                params = params.set('plan-role', planRole);

                return this.http.get<CancellationGift[]>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.CANCELLATION_GIFTS}`,
                    {
                        headers: defaultHeaders,
                        params
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public getClubStatus(): Observable<Club> {

        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.get<Club>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.CONFIG}`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        )
    }
}
