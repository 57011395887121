// Basic
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

// Modules
import { MaterialModule } from '@app/material.module';

// Components
import { SelectorDietsComponent } from './selector-diets.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule
  ],
  declarations: [SelectorDietsComponent],
  exports: [SelectorDietsComponent],
  entryComponents: [SelectorDietsComponent]
})

export class SelectorDietsModule { }
