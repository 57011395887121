import {Injectable, OnInit} from '@angular/core';
import { AuthApiService } from '@api/auth-api.service';
import { Router } from '@angular/router';
import { DATABASE_KEYS } from '@core/constants/storage.constants';
import { ApplicationDatabaseService } from 'providers/db/application-database.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from './logger.service';
import { LoadingService } from './loading.service';
import { ProfileDatabaseService } from '@db/profile-database.service';
import { UserService } from './user.service';
import { UsersApiService } from '@api/users-api.service';
import { Payment } from '@core/models/payment';
import { SnackBarService } from './snack-bar.service';
import { Location } from '@angular/common';
import { EventTrackerService } from '@services/tracking.service';
import {StripeService} from "@services/stripe.service";

const log = new Logger('auth.service.ts');

@Injectable({ providedIn: 'root' })
export class AuthService {

    private isAuthenticatedSubject: BehaviorSubject<boolean>;
    private stripe: any;
    private registrationToken: string;
    private stripeProductId: string;
    private userId: string;
    private userRole: string;

    constructor(
        private authApiService: AuthApiService,
        private router: Router,
        private applicationDatabaseService: ApplicationDatabaseService,
        private profileDatabaseService: ProfileDatabaseService,
        private loadingService: LoadingService,
        private userService: UserService,
        private usersApiService: UsersApiService,
        private snackBarService: SnackBarService,
        private location: Location,
        private eventTrackerService: EventTrackerService,
        private stripeService: StripeService,
    ) {
        this.isAuthenticatedSubject = new BehaviorSubject(false);
        this.applicationDatabaseService.get(DATABASE_KEYS.userToken).pipe(
            map(token => !!token)
        ).toPromise().then((isAuthenticated) => {
            this.isAuthenticatedSubject.next(isAuthenticated);
        });
    }

    public async doLogin(email: string, password: string) {
        try {
            this.loadingService.openLoading(false);
            const isMigrated = await this.isMigrated(email, password);
            if (isMigrated) {
                const userResponse = await this.authApiService.login(email, password).toPromise();
                await this.doSoftLogin(userResponse['token'], userResponse['id'], userResponse['role']) ;
            } else {
                this.router.navigate(['/migration'], {
                    queryParams: {
                        email
                    }
                });
            }
            this.loadingService.closeLoading();
        } catch (error) {
            this.loadingService.closeLoading();
            log.debug('Login error:', error);
        }
    }

    public async doSoftLogin(token: string, id: string, role: string, navigate?: string, impersonation: boolean = false) {
        try {
            await this.applicationDatabaseService.set(DATABASE_KEYS.userToken, token).toPromise();
            await this.applicationDatabaseService.set(DATABASE_KEYS.userId, id).toPromise();
            await this.applicationDatabaseService.set(DATABASE_KEYS.role, role).toPromise();
            await this.applicationDatabaseService.set(DATABASE_KEYS.impersonation, impersonation).toPromise();
            await this.userService.setCurrentUser();
            this.isAuthenticatedSubject.next(true);
            if (navigate) {
                this.router.navigate(['/' + navigate]);
            } else {
                this.router.navigate(['/weekly-menu']);
            }
        } catch (error) {
            log.debug('soft login error', error);
        }
    }

    private isMigrated(email: string, password?: string): Promise<boolean> {
        return this.authApiService.isUserMigrated(email, password).pipe(
            map((migration) => !migration || migration.status === 'migrated')
        ).toPromise();
    }

    public async doLogout() {
        try {
            this.loadingService.openLoading(false);
            await this.authApiService.logout().toPromise();
            this.profileDatabaseService.remove(DATABASE_KEYS.user);
            this.profileDatabaseService.remove(DATABASE_KEYS.subscription);
            this.applicationDatabaseService.remove(DATABASE_KEYS.userToken);
            this.applicationDatabaseService.remove(DATABASE_KEYS.registrationToken);
            this.applicationDatabaseService.remove(DATABASE_KEYS.userId);
            this.applicationDatabaseService.remove(DATABASE_KEYS.role);
            this.applicationDatabaseService.remove(DATABASE_KEYS.impersonation);
            this.isAuthenticatedSubject.next(false);
            this.router.navigate(['']);
            this.loadingService.closeLoading();
        } catch (error) {
            this.loadingService.closeLoading();
            log.debug('Logout error:', error);
        }
    }

    public async doRegistration(userInformation) {
        const { userData, paymentMethodId, paymentPlan } = userInformation;
        this.stripeProductId = userInformation.stripeProductId;
        this.stripe = this.stripeService.getStripe();
        this.registrationToken =  await this.applicationDatabaseService.get(DATABASE_KEYS.registrationToken).toPromise();
        this.userId = await this.applicationDatabaseService.get(DATABASE_KEYS.userId).toPromise();
        this.userRole = await this.applicationDatabaseService.get(DATABASE_KEYS.role).toPromise();
        try {
            this.loadingService.openLoading(false);
            // Second intent after subscription failed
            if ( this.registrationToken !== undefined && this.userId !== undefined && this.userRole !== undefined ) {
                // console.log('second time after first subscription failed');
                await this.checkAffiliatlyCookie(this.userId);
                await this.authApiService.createSubscriptionPayment(paymentMethodId,  this.registrationToken, this.stripeProductId).toPromise();
                await this.createStripeSubscription(
                    paymentPlan,
                    this.registrationToken,
                    this.userId,
                    this.userRole,
                    userData.coupon
                );
            // First intent to registration
            } else {
                // console.log("first time registration");
                const registrationResponse = await this.authApiService.register(userData).toPromise();
                await this.applicationDatabaseService.set(DATABASE_KEYS.registrationToken, registrationResponse.token).toPromise();
                await this.applicationDatabaseService.set(DATABASE_KEYS.userId, registrationResponse.id).toPromise();
                await this.applicationDatabaseService.set(DATABASE_KEYS.role, registrationResponse.role).toPromise();
                await this.checkAffiliatlyCookie(registrationResponse.id);
                await this.authApiService.createSubscriptionPayment(paymentMethodId, registrationResponse.token, this.stripeProductId).toPromise();
                await this.createStripeSubscription(
                    paymentPlan,
                    registrationResponse.token,
                    registrationResponse.id,
                    registrationResponse.role,
                    userData.coupon
                );
            }
            this.loadingService.closeLoading();
        } catch (error) {
            this.loadingService.closeLoading();
            log.debug('Registration error:', error);
        }
    }

    public async checkAffiliatlyCookie(userId) {
        const match = document.cookie.match(RegExp('(?:^|;\\s*)affiliatly_v3=([^;]*)'));
        if ( match ) {
            const params = this.parseQueryString(match[1]);
            const res = await this.usersApiService.linkUserToAffiliatly(userId, params).toPromise();
            // console.log(res);
        }
    }

    public parseQueryString(qs) {
        const q = decodeURIComponent;
        return qs.replace(/^\?/,'').split('&').map(s => s.split('=')).reduce((o,[k,v]) => (o[q(k)] = v?q(v):true, o), {});
    }

    public async createStripeSubscription(paymentPlan, registrationToken, userId, userRole, userCoupon) {
        try {
            this.loadingService.openLoading(false);
            const subscriptionResponse = await this.authApiService.createPaymentPlan(paymentPlan,  registrationToken, userCoupon, this.stripeProductId).toPromise();
            const success = await this.stripeService.handleSubscriptionResponse(subscriptionResponse);
            if (success) {
              this.loadingService.openLoading(false);
              await this.doSoftLogin( registrationToken, userId, userRole);
              this.sendTracking(paymentPlan, userCoupon, subscriptionResponse['payment_intent']['id']);
            } else {
              this.showError('Ha ocurrido un error inesperado procesando la tarjeta');
              this.loadingService.closeLoading();
            }
        } catch (error) {
            log.debug('Subscription error:', error);
            this.loadingService.closeLoading();
        }
    }

    public sendTracking(paymentPlan, userCoupon, paymentId) {
        console.log(paymentId);
        let event_gtag = {
            'event_category': 'club menu saludable',
            'event_label': paymentPlan.title
        };
        if (userCoupon) {
            event_gtag['coupon'] = userCoupon;
        }
        // Google analytics
        (window as any).gtag('event', 'alta', event_gtag);
        this.eventTrackerService.GTMPurchaseEvent(paymentPlan, this.stripeProductId, userCoupon, paymentId);
        // Tracking service: fb + pinterest
        this.eventTrackerService.FBTrackEvent('Purchase');
        this.eventTrackerService.FBPurchase(
            (paymentPlan.amount * 0.01),
            paymentPlan.currency.toUpperCase(),
            paymentPlan.id
        );
        this.eventTrackerService.PinterestCheckout(
            (paymentPlan.amount * 0.01),
            1,
            paymentPlan.currency.toUpperCase()
        );
    }

    public async doMigration(userData) {
        try {
            this.loadingService.openLoading(false);
            const isMigrated = await this.isMigrated(userData.email);
            if (!isMigrated) {
                const registrationResponse = await this.authApiService.register(userData).toPromise();
                await this.authApiService.migrateUser(registrationResponse['id'], userData.email).toPromise();
                await this.doSoftLogin(registrationResponse['token'], registrationResponse['id'], registrationResponse['role']);
            } else {
                this.showError('¡Vaya! Parece que tu usuario ya existe. Entra en la plataforma y comprueba si tu plan está activo.');
            }
            this.loadingService.closeLoading();
        } catch (error) {
            log.debug('Migration error:', error);
            this.loadingService.closeLoading();
        }
    }

    public async resubscribeUser(paymentPlan: Payment) {

      try {
          this.loadingService.openLoading(false);
          const subscriptionResponse = await this.authApiService.createPaymentPlan(paymentPlan).toPromise();
          const success = await this.stripeService.handleSubscriptionResponse(subscriptionResponse);
          if (!success) {
            log.debug('Resubscribe error');
          }
          await this.userService.setCurrentUser();
          this.loadingService.closeLoading();
      } catch (error) {
          log.debug('Migration error:', error);
          await this.userService.setCurrentUser();
          this.loadingService.closeLoading();
          this.showError('¡Vaya! Parece ser que ha habido un error inesperado. Comprueba tu tarjeta o cambiala por otra');
      }
    }

    public async cancelSubscription(stripeSubscriptionId: string) {
        try {
            this.loadingService.openLoading(false);
            await this.authApiService.unsubscribePlan(stripeSubscriptionId).toPromise();
            await this.userService.setCurrentUser();
            this.loadingService.closeLoading();

            //Google analytics
            (window as any).gtag('event', 'baja', {
                'event_category': 'club menu saludable',
                'event_label': stripeSubscriptionId
            });

        } catch (error) {
            this.loadingService.closeLoading();
            log.debug('Cancel Subscription', error);
        }
    }

    public async changePaymentMethod(subscription, hasSubscription) {

        try {
            this.loadingService.openLoading(false);
            if (hasSubscription) {
                await this.authApiService.changePaymentMethod(subscription).toPromise();
            } else {
                await this.authApiService.createSubscriptionPayment(subscription).toPromise();
            }
            await this.userService.setCurrentUser();
            this.loadingService.closeLoading();
        } catch (error) {
            this.loadingService.closeLoading();
            log.debug('Change payment method', error);
        }
    }

    public isAuthenticated(): Observable<boolean> {
        return this.isAuthenticatedSubject.asObservable();
    }

    public isAuthenticatedInstant(): boolean {
        return this.isAuthenticatedSubject.getValue();
    }

    public resetAccount(email: string) {
        return this.authApiService.resetAccount(email).toPromise();
    }

    public resetPassword(token: string, password: string) {
        return this.authApiService.resetPassword(token, password).toPromise();
    }

    private showError(error: string) {
        this.snackBarService.showSnackBarError({
            duration: 9000,
            data: {
                error
            }
        });
    }

    public validateCupon(cupon: string) {
        return this.authApiService.validateCupon(cupon).toPromise();
    }
}
