// Basic
import { Component } from '@angular/core';
import { Logger } from '@services/logger.service';

const log = new Logger('auxiliar-search-options-shell');

/**
 * Dapp-options-shell component
 */
@Component({
  selector: 'app-auxiliar-search-options-shell',
  templateUrl: 'auxiliar-search-options-shell.component.html',
  styleUrls: ['auxiliar-search-options-shell.component.scss']
})
export class AuxiliarSearchOptionsShellComponent {

  constructor(
  ) { }

  public search() {
    log.debug('do Search');
  }

}
