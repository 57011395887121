// Basic
import {Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-selector-people-menu',
    templateUrl: 'selector-people-menu.component.html',
    styleUrls: ['selector-people-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SelectorPeopleMenuComponent implements OnInit {

    @Input() menuNumber: number;
    @Output() updateMenuNumber = new EventEmitter<number>();
    public menuType: string;

    constructor() {
    }

    public ngOnInit() {
        this.menuType = 'ADULTOS';
        this.updateMenuNumber.emit(this.menuNumber);
    }

    public addMenu() {
        this.menuNumber += 1;
        this.updateMenuNumber.emit(this.menuNumber);
    }

    public removeMenu() {
        this.menuNumber = this.menuNumber > 1 ? this.menuNumber -= 1 : 1;
        this.updateMenuNumber.emit(this.menuNumber);
    }

}
