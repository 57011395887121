import { Injectable } from '@angular/core';

import { cookieConfig } from '@core/config/cookie-banner.config';

import { ApplicationDatabaseService } from 'providers/db/application-database.service';
import { DATABASE_KEYS } from '@core/constants/storage.constants';

@Injectable({ providedIn: 'root' })
export class CookieBannerService {

    private popupContext: any;

    constructor(
        private applicationDatabaseService: ApplicationDatabaseService
    ) { }

    public init() {
        this.applicationDatabaseService.get(DATABASE_KEYS.cookies).toPromise().then(cookieAccepted => {
            if (!cookieAccepted) {
                this.initCookiePopup();
            }
        })
    }


    private initCookiePopup() {
        let config: any = { ...cookieConfig };
        // Set callbacks hooks:
        config.onStatusChange = () => {
            this.acceptCookies();
        };
        // Init the cookieconsent library with injected config
        (<any>window).cookieconsent.initialise(config, (popup) => this.popupContext = popup);
    }

    private async acceptCookies() {
        await this.applicationDatabaseService.set(DATABASE_KEYS.cookies, true).toPromise();
    }

    public getContext() {
        return this.popupContext;
    }
}