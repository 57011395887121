// Basic
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

// Constants
import { UserService } from '@services/user.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-selector-diets',
    templateUrl: 'selector-diets.component.html',
    styleUrls: ['selector-diets.component.scss']
})

export class SelectorDietsComponent implements OnInit, OnDestroy {

    @Input() isVegan: boolean;
    @Output() setIsVegan = new EventEmitter<boolean>();

    public users$: Subscription;

    constructor(
        private userService: UserService
    ) {
    }

    public ngOnInit() {
        this.users$ = this.userService.getMe().subscribe( (user) => {
            this.isVegan = user.vegan;
        });
        this.setIsVegan.emit(this.isVegan);
    }

    public ngOnDestroy() {
    }

    public setVeganDiet(diet: boolean) {
        this.isVegan = diet;
        this.setIsVegan.emit(this.isVegan);
    }

}
