import { ENDPOINTS } from '@constants/endpoints.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DailyMenu } from '@core/models/dailyMenu';
import { GenericAPI } from './generic-api.service';
import { environment } from '@env';
import { Observable, from, throwError } from 'rxjs';
import { ApplicationDatabaseService } from 'providers/db/application-database.service';
import { flatMap, finalize, share, catchError } from 'rxjs/operators';
import { LoadingService } from '@services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class MenusApiService extends GenericAPI {

  constructor(
    private http: HttpClient,
    public applicationDatabaseService: ApplicationDatabaseService,
    private loadingService: LoadingService
  ) {
    super(applicationDatabaseService);
  }

  public getMenu(date): Observable<DailyMenu[]> {

    this.loadingService.openLoading();

    return from(this.getGenericHeaders()).pipe(
      flatMap((defaultHeaders) => {

        const params = new HttpParams()
          .set('date', date);

        return this.http.get<DailyMenu[]>(
          `${environment.rest.baseUrl}/${ENDPOINTS.MENU}`,
          {
            params,
            headers: defaultHeaders
          }
        ).pipe(
          share(),
          finalize(() => this.loadingService.closeLoading()),
          catchError(error => {
            return throwError(error);
        })
        );
      })
    )
  }
}
