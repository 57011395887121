// Basic
import { Component } from '@angular/core';
import { Logger } from '@services/logger.service';

const log = new Logger('mobile-bottom-menu')

/**
 * Dapp-options-shell component
 */
@Component({
  selector: 'app-mobile-bottom-menu',
  templateUrl: 'mobile-bottom-menu.component.html',
  styleUrls: ['mobile-bottom-menu.component.scss']
})
export class MobileBottomMenuComponent {

  constructor() { }

}
