// Basic
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

// Modules
import { MaterialModule } from '@app/material.module';

// Components
import { AuxiliarDownloadOptionsShellComponent } from './auxiliar-download-options-shell.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule
  ],
  declarations: [AuxiliarDownloadOptionsShellComponent],
  exports: [AuxiliarDownloadOptionsShellComponent],
  entryComponents: [AuxiliarDownloadOptionsShellComponent]
})
export class AuxiliarDownloadOptionsShellModule { }
