import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { Logger } from '@services/logger.service';
import { SnackBarService } from '@services/snack-bar.service';
import { ERROR_MESSAGE } from '@core/constants/error-api.constants';
import { ENDPOINTS } from '@core/constants/endpoints.constants';

const log = new Logger('api.interceptor');

export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private snackBarService: SnackBarService
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    private handleError(error: HttpErrorResponse) {
        switch (error.status) {
            case 400: {
                if (error.url.includes(ENDPOINTS.PAYMENT_STATUS)) {
                    this.showError('Error procesando la transacción, intentalo de nuevo más tarde.');
                    this.authService.doLogout();
                }
                break;
            }
            case 401:
            case 403: {
                log.debug('Unauthorized!');
                const isAuthenticated = this.authService.isAuthenticatedInstant();
                if (isAuthenticated) {
                    this.showError('Sesión expirada, inicia sesión de nuevo.');
                    this.authService.doLogout();
                } else {
                    this.showError('Email o contraseña incorrectos.');
                }
                break;
            }

            case 404: {
                this.showError('Error procesando la petición, revisa los datos.');
                break;
            }

            case 422: {
                if (error.error.message === ERROR_MESSAGE.USER_ALREADY_EXISTS) {
                    this.showError('¡Vaya! Parece que tu usuario ya existe. Entra en la plataforma y comprueba si tu plan está activo.');
                } else {
                    this.showError('Error procesando la transacción, intentalo de nuevo más tarde.');
                }
                break;
            }

            case 423: {
                if (error.error.message === ERROR_MESSAGE.PAYMENT_PROVIDER_ERROR) {
                    this.showError('Error en la plataforma de pago, contacta con nosotros');
                } else {
                    this.showError('Error en la plataforma de pago, intentalo de nuevo más tarde.');
                }
                this.authService.doLogout();
                break;
            }
            case 500: {
                if (error.error && error.error.message) {
                    this.showError(error.error.message);
                } else {
                    this.showError('Error no controlado, intentalo de nuevo más tarde.');
                }
                break;
            }

            case 504: {
                this.showError('Parece que el servidor tarda mucho en contestar, intentalo de nuevo más tarde')
                break;
            }

            default:
                if (error.error && error.error.message) {
                    this.showError(error.error.message);
                }
                break;
        }
        return throwError(error);
    }

    private showError(error: string) {
        this.snackBarService.showSnackBarError({
            duration: 4000,
            panelClass: ['snack-bar-error'],
            data: {
                error
            }
        });
    }
}
