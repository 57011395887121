import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeComponent } from './welcome.component';
import { WelcomeRoutingModule } from './welcome-routing.module';
import { FooterModule } from '@components/footer/footer.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PaymentSubscriptionModule } from '@components/payment-subscription/payment-subscription.module';
import { MaterialModule } from '@app/material.module';
import { DirectivesModule } from '@directives/directives.module';


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FooterModule,
    PaymentSubscriptionModule,
    MaterialModule,
    WelcomeRoutingModule,
    DirectivesModule
  ],
  declarations: [WelcomeComponent]
})
export class WelcomeModule { }
