import { ENDPOINTS } from '@constants/endpoints.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericAPI } from 'providers/api/generic-api.service';
import { ApplicationDatabaseService } from 'providers/db/application-database.service';
import { Observable, from, throwError } from 'rxjs';
import { flatMap, share, catchError } from 'rxjs/operators';
import { environment } from '@env';
import { LoadingService } from '@services/loading.service';
import { Subscription } from '@core/models/subscriptionStatus';

@Injectable({
    providedIn: 'root'
})
export class StripeApiService extends GenericAPI {

    constructor(
        private http: HttpClient,
        public applicationDatabaseService: ApplicationDatabaseService,
        private loadingService: LoadingService
    ) {
        super(applicationDatabaseService);
    }

    public getIntent(): Observable<any> {
        this.loadingService.openLoading(false);
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.get<any>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.STRIPE_INTENT}`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        this.loadingService.closeLoading();
                        return throwError(error);
                    })
                );
            })
        );
    }

    public getPaymentStatus(): Observable<Subscription> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.get<Subscription>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.PAYMENT_STATUS}`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public pauseResumeSubscription(behaviour: string, resumes_at?: string): Observable<Subscription> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded');

                let body = new HttpParams()
                    .append('behaviour', behaviour);

                if (resumes_at) {
                    body = body.set('resumes_at', resumes_at)
                }

                return this.http.put<Subscription>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.PAUSE_RESUME_SUBSCRIPTION}`,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public acceptCancellationGift(giftId: number) {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded');

                let body = new HttpParams()
                    .append('behaviour', 'GIFT');

                if (giftId) {
                    body = body.set('gift_id', giftId.toString());
                }

                return this.http.put<any>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.PAUSE_RESUME_SUBSCRIPTION}`,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public sendCancellationReason(stripeSubscriptionId: string, reasonKey: string, comment?: string) {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded');

                let body = new HttpParams();

                if (comment) {
                    body = body.append('comments', comment.toString());
                }

                return this.http.post(
                    `${environment.rest.baseUrl}/subscriptions/${stripeSubscriptionId}/cancellation_reasons/${reasonKey}`,
                    body,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );

    }

}
