// Basic
import { Component, ViewEncapsulation, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { Payment } from '@core/models/payment';
import { ProductsConfig } from '@core/models/productsConfig';
import { PaymentPlanService } from '@services/payment-plan.service';
import { Logger } from '@services/logger.service';
import {ActivatedRoute} from "@angular/router";
import {StripeService} from "@services/stripe.service";

const log = new Logger('payment-subscription.component');

@Component({
    selector: 'app-payment-subscription',
    templateUrl: 'payment-subscription.component.html',
    styleUrls: ['payment-subscription.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PaymentSubscriptionComponent implements OnInit {

    @Output() public paymentSubscription = new EventEmitter();
    @Input() public bgColor: string;
    @Input() public showDiscounts: boolean;
    @Input() public selectedPlanIndex: number;
    @Input() public fromRegister: boolean;

    public stripeProductId: string;
    public hasEnrollmentFee: boolean;
    public productsConfig: ProductsConfig;
    public paymentTypes: Payment[];
    public paymentSelected: Payment;
    public discount: Payment;

    constructor(
        private activatedRoute: ActivatedRoute,
        private paymentPlanService: PaymentPlanService,
        private stripeService: StripeService
    ) {
    }

    public async ngOnInit() {
        const productId = this.stripeService.getStripeProductIdFromUrl();
        this.selectedPlanIndex = ( this.selectedPlanIndex || this.selectedPlanIndex === 0 ) ? this.selectedPlanIndex : 2;
        try {
            const plans = await this.paymentPlanService.getPaymentPlans(productId);
            this.productsConfig = await this.paymentPlanService.getProductsConfig(productId);
            this.hasEnrollmentFee = this.productsConfig ? this.productsConfig.enrollment_fee : false;
            this.paymentTypes = plans;
            this.discount = this.paymentSelected = plans[this.selectedPlanIndex];
            this.paymentSubscription.emit({
                payment: this.paymentSelected,
                index: this.selectedPlanIndex,
                stripeId: this.productsConfig.stripe_id,
                init: true
            });
        } catch (error) {
            log.debug(error);
        }
    }

    public changeSelector(index) {
        const { value } = index;
        this.paymentSelected = this.paymentTypes[value];
        this.paymentSubscription.emit({
            payment: this.paymentSelected,
            index: value,
            stripeId: this.productsConfig.stripe_id,
        });
    }

    public getDiscount(payment: Payment) {
        if (payment && parseInt(payment.metadata.discount)) {
            return `¡Ahorra un ${payment.metadata.discount}%!`;
        }
    }

    public getPricePerWeek(payment: Payment): string {
        let partialResult: number;
        if (payment) {
            if (payment.interval === 'month') {
                partialResult = ((payment.amount / 100) / payment.interval_count) / 4.33;
            } else {
                partialResult = ((payment.amount / 100) / 52);
            }
            return partialResult.toFixed(2);
        }
    }
}
