import { Injectable } from '@angular/core';
import { defaultSnackBarConfig } from '@config/snack-bar.config';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarGeneralErrorComponent } from '@components/snack-bar-general-error/snack-bar-general-error.component';

@Injectable({ providedIn: 'root' })
export class SnackBarService {

    private defaultConfig: object;
    private snackbar: MatSnackBarRef<any>;

    constructor(
        private matSnackBar: MatSnackBar
    ) {
        this.defaultConfig = defaultSnackBarConfig;
    }

    public showSnackBar(message = '', action = '', config?: MatSnackBarConfig) {
        const snackBarConfig = { ...this.defaultConfig, ...config };
        this.matSnackBar.open(message, action, snackBarConfig);
    }

    public showSnackBarError(config?: MatSnackBarConfig) {
        if (!this.snackbar) {
            const snackBarConfig = { ...this.defaultConfig, ...config };
            this.snackbar = this.matSnackBar.openFromComponent(SnackBarGeneralErrorComponent, snackBarConfig);
            this.snackbar.afterDismissed().subscribe(() => {
                this.snackbar = undefined;
            });
        }
    }
}
