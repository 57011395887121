import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DATABASE_KEYS } from '@core/constants/storage.constants';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Logger } from './logger.service';
import { User } from '@core/models/user';
import { UsersApiService } from '@api/users-api.service';
import { ProfileDatabaseService } from 'providers/db/profile-database.service';
import { ApplicationDatabaseService } from 'providers/db/application-database.service';
import { share } from 'rxjs/operators';
import { StripeService } from './stripe.service';
import { LoadingService } from './loading.service';

const log = new Logger('user.service.ts');

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private userSubject: BehaviorSubject<User>;

    constructor(
        private usersApiService: UsersApiService,
        private profileDatabaseService: ProfileDatabaseService,
        private applicationDatabaseService: ApplicationDatabaseService,
        private stripeService: StripeService,
        private loadingService: LoadingService,
    ) {
        this.userSubject = new BehaviorSubject(undefined);
        this.getCurrentUser();
    }

    private async getCurrentUser() {
        try {
            const subscription = await this.profileDatabaseService.get(DATABASE_KEYS.subscription).toPromise();
            const userResponse = await this.profileDatabaseService.get(DATABASE_KEYS.user).toPromise();
            this.userSubject.next({ ...userResponse, ...{ subscription } });
        } catch (error) {
            log.debug('Login error:', error);
        }
    }

    public async setCurrentUser() {
        try {
            const subscription = await this.stripeService.getPaymentStatus();
            const userResponse = await this.usersApiService.getMe().toPromise();
            await this.profileDatabaseService.set(DATABASE_KEYS.user, userResponse).toPromise();
            await this.profileDatabaseService.set(DATABASE_KEYS.subscription, subscription).toPromise();
            this.userSubject.next({ ...userResponse, ...{ subscription } });
        } catch (error) {
            log.debug('Login error:', error);
        }
    }

    public async updateUser(params) {
        this.loadingService.openLoading(false);
        try {
            const userId = await this.applicationDatabaseService.get(DATABASE_KEYS.userId).toPromise();
            await this.usersApiService.updateUser(userId, params).toPromise();
            await this.setCurrentUser();
            this.loadingService.closeLoading();
        } catch (error) {
            log.debug('upadate user error:', error);
            this.loadingService.closeLoading();
        }
    }

    public async updateAvatar(avatar: File) {
        return this.usersApiService.uploadAvatar(avatar).toPromise();
    }

    public getMe(): Observable<User> {
        return this.userSubject.asObservable().pipe(share());
    }

    public userAlreadyExists(email: string) {
        return this.usersApiService.userAlreadyExists(email).toPromise();
    }

    public cleanUser() {
        this.userSubject.next(undefined);
        this.profileDatabaseService.remove(DATABASE_KEYS.user);
    }
}
