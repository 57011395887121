import { Injectable } from "@angular/core";
import { ShoppingListApiService } from '@api/shopping-list-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ingredient } from '@core/models/ingredient';
import { Category } from '@core/models/category';

@Injectable({ providedIn: 'root' })
export class ShoppingListService {
    constructor(
        private shoppingListApiService: ShoppingListApiService
    ) { }

    public getList(date: string, order: string, token?: string): Promise<Category[]> {
        return this.shoppingListApiService.getList(date, order, token).toPromise();
    }

    public setBought(ingredient_id: number, menu_id: number): Observable<any> {
        return this.shoppingListApiService.setBought(ingredient_id, menu_id);
    }

    public unsetBought(ingredient_id: number, menu_id: number): Observable<any> {
        return this.shoppingListApiService.unsetBought(ingredient_id, menu_id);
    }
}
