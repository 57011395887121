// Basic
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

// Constants
import { AllergensService } from '@services/allergens.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-selector-allergens',
    templateUrl: 'selector-allergens.component.html',
    styleUrls: ['selector-allergens.component.scss']
})

export class SelectorAllergensComponent implements OnInit, OnDestroy {

    @Input() allergenSelected: number;
    @Output() updateAllergen = new EventEmitter<number>();

    public allergens: object;
    public allergens$: Subscription;

    constructor(
        private allergenService: AllergensService
    ) {
    }

    public ngOnInit() {
        this.allergens$ = this.allergenService.getAllergens().subscribe((allergens) => {
            this.allergens = allergens;
        });
        this.updateAllergen.emit(this.allergenSelected);
    }

    public ngOnDestroy() {
        this.allergens$.unsubscribe();
    }

    public setAllergen(allergen: number) {
        this.allergenSelected = allergen;
        this.updateAllergen.emit(this.allergenSelected);
    }

}
