export const PDF_CONSTANTS = {
    pageHeight: 260,
    marginTop: 10,
    marginBottom: 25,
    marginLeft: 10,
    marginRight: 10,
    primaryColor: '#8C5775',
    primaryColorDark: '#905E7A',
    secondaryColor: '#219C99',
    textColor: '#454545',
    primaryBackgroundColor: '#e968dc',
    fillColor: '#c5e2df',
    lineColor: '#e6bde2',
    primaryFontFamily: 'Century',
    secondaryFontFamily: 'Brownhill',
    thirdFontFamily: 'Century'
};

//Function title for all PDF's 
export const IMAGE_TITLE = {
    width: 30,
    height: 30.1
};

// Shopping List
export const SHOPPING_LIST = {
    titleIndex: 10,
    firstPageIndex: 50,
    xInitialPositionFC: 10,
    xInitialPositionSC: 110,
    xFinalPositionFC: 100,
    xFinalPositionSC: 200,
    // For Ingredient Category
    splitMaxWidthIC: 70,
    rectHeight1Line: 5,
    rectHeight2Line: 7,
    rectHeight3Line: 9,
    // For Ingredient Name
    splitMaxWidthIN: 40
};

export const RECIPE_CONSTANTS = {
    titleIndex: 2,
    firstPageIndex: 40,
    yImageRecipePosition: 100,
    xInitialPositionFC: 10,
    xInitialPositionSC: 110,
    xFinalPositionFC: 100,
    xFinalPositionSC: 200,
    // Image recipe
    width: 72,
    height: 50,
    // Icons
    dimensionIcons: 5,
    // Function print details
    xPositionSC: 85,
    xPositionTag: 140,
    // For print ingredients
    splitMaxWidthPI: 40,
    rectHeight1Line: 0,
    rectHeight2Line: 4,
    rectHeight3Line: 9
};

export const BATCH_COOKING = {
    titleIndex: 10,
    firstImageIndex: 40,
    emptySpaceIndex: 271,
    firstPageIndex: 50,
    xFinalPositionFC: 100,
    // For print ingredients
    splitMaxWidthPI: 40,
    rectHeight1Line: 5,
    rectHeight2Line: 10,
    rectHeight3Line: 18,
    // For print used recipe
    splitMaxWidthPIR: 105,
    rectHeight1LinePIR: 8,
    rectHeight2LinePIR: 11,
    rectHeight3LinePIR: 13
};

export const WEEKLY_MENU = {
    titleIndex: 10,
    firstPageIndex: 50,
    // For cover page
    xPosition: 0,
    yPosition: 0,
    width: 210,
    height: 300,
    // For text in cover page
    xPositionT: 155,
    yPositionT: 48,
    splitMaxWidthT: 50,
    xInitialPositionFC: 10,
    xInitialPositionSC: 110,
};
