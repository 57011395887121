import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import {Moment} from "moment";

@Injectable({ providedIn: 'root' })
export class DateServices {

    private weekObserver: BehaviorSubject<moment.Moment>;

    private week: moment.Moment;

    constructor() { }

    public init() {
        this.week = moment().startOf('isoWeek');
        this.weekObserver = new BehaviorSubject(moment().startOf('isoWeek'));
    }

    public getWeek(): Date {
        return this.week.toDate();
    }

    public getWeekObserver(): Observable<moment.Moment> {
        return this.weekObserver.asObservable().pipe(share());
    }

    public getFormattedWeek(): string {
        return `${this.week.startOf('isoWeek').format('DD')} - ${this.week.endOf('isoWeek').format('DD MMMM')}`;
    }

    public setNextWeek() {
        this.week = this.week.add(1, 'week').startOf('isoWeek');
        this.weekObserver.next(this.weekObserver.getValue().add(1, 'week').startOf('isoWeek'));
    }

    public setPreviousWeek() {
        this.week = this.week.subtract(1, 'week').startOf('isoWeek');
        this.weekObserver.next(this.weekObserver.getValue().subtract(1, 'week').startOf('isoWeek'));
    }

    public setWeek(date: Moment) {
        this.week = date.startOf('isoWeek');
        this.weekObserver.next(date.startOf('isoWeek'));
    }

}
