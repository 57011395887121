import { ENDPOINTS } from '@constants/endpoints.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericAPI } from './generic-api.service';
import { environment } from '@env';
import { Observable, from, throwError } from 'rxjs';
import { ApplicationDatabaseService } from 'providers/db/application-database.service';
import { flatMap, share, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthApiService extends GenericAPI {

    constructor(
        private http: HttpClient,
        public applicationDatabaseService: ApplicationDatabaseService
    ) {
        super(applicationDatabaseService);
    }

    public login(email: string, password: string): Observable<any> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded');

                const body = new HttpParams()
                    .set('email', email)
                    .set('password', password);

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.LOGIN}`,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public isUserMigrated(email: string, password: string): Observable<any> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded');

                const params = new HttpParams()
                    .set('email', email)
                    .set('password', password);

                return this.http.get(
                    `${environment.rest.baseUrl}/${ENDPOINTS.IS_MIGRATED}`,
                    {
                        headers: defaultHeaders,
                        params: params
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public migrateUser(userId: string, email: string) {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {
                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded');

                const body = new HttpParams()
                    .set('email', email)
                    .set('user_id', userId)

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.MIGRATION}`,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public register(userData): Observable<any> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded');


                const body = new HttpParams()
                    .set('first_name', userData.name)
                    .set('last_name', userData.surname)
                    .set('email', userData.email)
                    .set('type', 'REGULAR')
                    .set('country_code', userData.country)
                    .set('postal_code', userData.cp)
                    .set('password', userData.password)

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.REGISTER}`,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public validateCupon(cupon: string) {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.get(
                    `${environment.rest.baseUrl}/payments/coupon/${cupon}`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public resetAccount(email: string) {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.post(
                    `${environment.rest.baseUrl}/users/${email}/password_change_request/`,
                    undefined,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public resetPassword(token: string, password: string) {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded')

                const body = new HttpParams()
                    .set('password', password);


                return this.http.put(
                    `${environment.rest.baseUrl}/users/${token}/password/`,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public createSubscriptionPayment(paymentMethodId, token?, stripeProductId?): Observable<any> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded')


                if (token) {
                    defaultHeaders = defaultHeaders
                        .set('token', token);
                }

                const body = new HttpParams()
                    .set('payment_method', paymentMethodId);

                let endpoint = `${environment.rest.baseUrl}/${ENDPOINTS.ADD_PAYMENT_SUBSCRIPTION}`;
                if (stripeProductId) {
                  endpoint = endpoint + `?stripe_product_id=${stripeProductId}`;
                }

                return this.http.post(
                    endpoint,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public changePaymentMethod(payment_method): Observable<any> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded')

                const body = new HttpParams()
                    .set('payment_method', payment_method);


                return this.http.put(
                    `${environment.rest.baseUrl}/${ENDPOINTS.ADD_PAYMENT_SUBSCRIPTION}`,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public unsubscribePlan(stripeSubscriptionId: string) {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.delete(
                    `${environment.rest.baseUrl}/${ENDPOINTS.CREATE_PAYMENT_PLAN}/${stripeSubscriptionId}`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public createPaymentPlan(paymentPlan, token?: string, coupon?: string, stripeProductId?: string): Observable<any> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded')

                if (token) {
                    defaultHeaders = defaultHeaders
                        .set('token', token);
                }

                let body = new HttpParams()
                    .set('plan_id', paymentPlan.id)
                    .set('plan_role', paymentPlan.metadata["plan-role"]);

                if (coupon) {
                    body = body
                        .append('coupon', coupon);
                }

                let endpoint = `${environment.rest.baseUrl}/${ENDPOINTS.CREATE_PAYMENT_PLAN}`;
                if (stripeProductId) {
                  endpoint = endpoint + `?stripe_product_id=${stripeProductId}`;
                }

                return this.http.post(
                    endpoint,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public logout() {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {
                return this.http.delete(
                    `${environment.rest.baseUrl}/${ENDPOINTS.LOGOUT}`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }
}
