
export const CUSTOM_BREAKPOINTS = [
    {
        alias: 'xs',
        mediaQuery: 'screen and (max-width: 576px)'
    },
    {
        alias: 'sm',
        mediaQuery: 'screen and (min-width: 576px) and (max-width: 768px)'
    },
    {
        alias: 'md',
        mediaQuery: 'screen and (min-width: 768px) and (max-width: 992px)'
    },
    {
        alias: 'lg',
        mediaQuery: 'screen and (min-width: 992px) and (max-width: 1200px)'
    },
    {
        alias: 'xl',
        mediaQuery: 'screen and (min-width: 1200px)'
    },
    {
        alias: 'lt-md',
        mediaQuery: 'screen and (max-width: 768px)'
    },
    {
        alias: 'lt-lg',
        mediaQuery: 'screen and (max-width: 992px)'
    },
    {
        alias: 'lt-xl',
        mediaQuery: 'screen and (max-width: 1200px)'
    },
    {
        alias: 'gt-sm',
        mediaQuery: 'screen and (min-width: 576px)'
    },
    {
        alias: 'gt-md',
        mediaQuery: 'screen and (min-width: 768px)'
    },
    {
        alias: 'gt-lg',
        mediaQuery: 'screen and (min-width: 992px)'
    }
];

export const CUSTOM_BREAKPOINTS_KEYS = {
    XS: 'xs',
    LT_SM: 'lt-sm',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl'
}
