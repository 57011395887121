export const OPINION_MOCK = [
    {
        avatar: 'assets/images/png/avatar-placeholder.png',
        name: 'Ana',
        body: 'Estoy encantada, esto es lo que realmente estaba buscando por mi ritmo de vida. Genial!!!'
    },
    {
        avatar: 'assets/images/png/avatar-placeholder.png',
        name: 'David',
        body: 'Lo mejor es no tener que estar pensando que hacer de comer. Y si estás tomando todos los nutrientes ' +
            'que tu cuerpo necesita, te hace la vida más facil! Y si encima lo que comes está buenísimo ya es lo más!!'
    },
    {
        avatar: 'assets/images/png/avatar-placeholder.png',
        name: 'Laura',
        body: 'Me encantan los menús propuestos. Estoy descubriendo muchos sabored nuevos y aprendiendo a introducir ' +
            'más variedad de cereales y legumbres.'
    }
];
