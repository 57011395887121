// Core components
import { Component, Input } from '@angular/core';
import { Footer } from '@core/models/footer';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  @Input() public footerData: Footer[];

  constructor(
    private router: Router
  ) { }

  public navigate(option: Footer) {
    if(option.externalLink) {
      window.location.href = option.externalLink;
    } else {
      this.router.navigate(option.routerLink);
    }
  }

}
