// Core components
import { Component, OnInit, ViewEncapsulation, ViewChild, ComponentFactoryResolver, OnDestroy, ViewContainerRef, ComponentRef } from '@angular/core';
import { ActivatedRoute, Router, Route, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';

// Dialog
import { MobileSubmenuDialogComponent } from '@components/mobile-submenu-dialog/mobile-submenu-dialog.component';

// Rx
import { filter, map, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

// Directives
import { AppShellAuxiliarButtonsHostDirective } from '@directives/shell-auxiliar-buttons';

// Models
import { MenuOption } from '@core/models/menuOption';

// Services
import { Logger } from '@services/logger.service';
import { ColorSchemeService } from '@services/color-scheme.service';

// Constants
import { CUSTOM_BREAKPOINTS_KEYS } from '@core/constants/customBreakpoints.constants';
import { DateServices } from '@services/date.service';
import { Footer } from '@core/models/footer';
import { UserService } from '@services/user.service';
import { User } from '@core/models/user';
import { AuthService } from '@services/auth.service';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

const log = new Logger('shell.component.ts');

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ShellComponent implements OnInit, OnDestroy {

    private mediaSubscription: Subscription;
    private auxiliarButtonsMessageComponent: ComponentRef<unknown>;
    private _auxiliarButtons: ViewContainerRef;
    private date$: Subscription;
    private user$: Subscription;

    public isMobile: boolean;
    public data: Object;
    public menuOptions: MenuOption[];
    public submenuOptions: MenuOption[];
    public week: string;
    public footerOptions: Footer[];
    public user: User;
    public countPrevWeeks: number;
    public countNextWeeks: number;

    public useDefault: boolean;
    public colorScheme: string;

    @ViewChild(AppShellAuxiliarButtonsHostDirective, { read: ViewContainerRef, static: false }) set auxiliarButtons(content: ViewContainerRef) {
        this._auxiliarButtons = content;
        this.data = this.findChildRoute(this.activatedRoute).data.getValue();
        this.loadAuxiliarOptions(this.data);
    }

    constructor(
        private router: Router,
        private componentFactoryResolver: ComponentFactoryResolver,
        private activatedRoute: ActivatedRoute,
        private mediaObserver: MediaObserver,
        private location: Location,
        private dateService: DateServices,
        private dialog: MatDialog,
        private userService: UserService,
        private authService: AuthService,
        public colorSchemeService: ColorSchemeService
    ) {
        this.countNextWeeks = 0;
        this.countPrevWeeks = 0;
        this.colorScheme = localStorage.getItem('prefers-color');

        this.menuOptions = [
            {
                path: ['/weekly-menu'],
                icon: 'venu-Menu',
                text: 'Menú semanal',
                disabled: false
            },
            {
                path: ['/list'],
                icon: 'venu-Lista_Compra',
                text: 'Lista de la compra',
                disabled: false
            },
            {
                path: ['/batch'],
                icon: 'venu-Batch',
                text: 'Batch Cooking',
                disabled: false
            },
            {
                path: ['/recipes'],
                icon: 'venu-Recetas',
                text: 'Recetas',
                disabled: false
            }

        ];

        this.submenuOptions = [
            {
                path: ['/profile'],
                text: 'Perfil',
                disabled: false
            },
            {
                path: ['/preferences'],
                text: 'Preferencias',
                disabled: false
            },
            {
                path: ['/subscription'],
                text: 'Suscripción',
                disabled: false
            }
        ];
    }

    public ngOnInit() {

        this.useDefault = this.colorScheme === 'dark';

        this.mediaSubscription = this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
            if (changes) {
                this.isMobile = !!changes.filter((change) => {
                    return change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.LT_SM ||
                        change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.SM || change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.XS;
                }).length;
            }
        });

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        ).subscribe((event) => {
            this.data = event;
            this.loadAuxiliarOptions(event);
        });

        this.date$ = this.dateService.getWeekObserver().subscribe((week) => {
            this.week = this.dateService.getFormattedWeek();
        });

        this.user$ = this.userService.getMe().subscribe((user) => {
            this.user = user;
        });
    }

    public externalLinkTo(option?: string) {
        window.location.href = option;
    }

    public ngOnDestroy() {
        this.date$.unsubscribe();
        this.user$.unsubscribe();
        this.mediaSubscription.unsubscribe();
        if (this.auxiliarButtonsMessageComponent) {
            this.auxiliarButtonsMessageComponent.changeDetectorRef.detach();
        }
    }

    public toggle(event: MatSlideToggleChange) {
        this.useDefault = event.checked;
        this.colorScheme = this.useDefault === true ? 'dark' : 'light';
        this.colorSchemeService.update( this.colorScheme );
    }

    public doLogout() {
        this.authService.doLogout();
    }

    private loadAuxiliarOptions(event) {
        if (this._auxiliarButtons) {
            this._auxiliarButtons.clear();
            if (event.shellOptions && event.shellOptions.auxiliarOptionsComponent) {
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(event.shellOptions.auxiliarOptionsComponent);
                if (this.auxiliarButtonsMessageComponent) {
                    this.auxiliarButtonsMessageComponent.changeDetectorRef.detach();
                }
                this.auxiliarButtonsMessageComponent = this._auxiliarButtons.createComponent(componentFactory);
                this.auxiliarButtonsMessageComponent.changeDetectorRef.detectChanges();

            }
        }
    }

    private findChildRoute(route): Route {
        if (route.firstChild === null) {
            return route;
        } else {
            return this.findChildRoute(route.firstChild);
        }
    }

    public back() {
        this.location.back();
    }

    public nextWeek() {
        if (this.countNextWeeks < this.user.subscription.week_offset_future) {
            this.countNextWeeks = ++this.countNextWeeks;
            this.countPrevWeeks = --this.countPrevWeeks;
            this.dateService.setNextWeek();
        }
    }

    public previousWeek() {
        if (this.countPrevWeeks < this.user.subscription.week_offset_past) {
            this.countPrevWeeks = ++this.countPrevWeeks;
            this.countNextWeeks = --this.countNextWeeks;
            this.dateService.setPreviousWeek();
        }
    }

    public showMenuAccount() {
        this.dialog.open(MobileSubmenuDialogComponent, {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            panelClass: 'fullscreen-dialog',
            disableClose: true,
            autoFocus: false,
            hasBackdrop: false,
        });
    }
}
