import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { loadingConfig } from '@core/config/loading.config.ts';

@Injectable({ providedIn: 'root' })
export class LoadingService {

    public isLoading: BehaviorSubject<boolean>;
    public isSoftLoader: BehaviorSubject<boolean>;

    constructor() {
        this.isLoading = new BehaviorSubject(false);
        this.isSoftLoader = new BehaviorSubject(true);
    }

    public openLoading(isSoftLoader = true) {
        if (!this.isLoading.getValue()) {
            this.isSoftLoader.next(isSoftLoader);
            this.isLoading.next(true);
            setTimeout(() => {
                this.closeLoading();
            }, loadingConfig.maxLoadingTimeout);
        }
    }

    public closeLoading() {
        if (this.isLoading.getValue()) {
            //Delay the close of the loading to make it pretty.
            setTimeout(() => {
                this.isLoading.next(false);
            }, loadingConfig.timeoutToClose);
        }
    }

    public loadingObserver(): Observable<boolean> {
        return this.isLoading.asObservable().pipe(share());
    }

    public isSoftLoaderObserver(): Observable<boolean> {
        return this.isSoftLoader.asObservable().pipe(share());
    }
}
