import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ENDPOINTS } from '@constants/endpoints.constants';
import { BatchElement } from '@core/models/batchElement';
import { environment } from '@env';
import { GenericAPI } from './generic-api.service';
import { Observable, from, throwError } from 'rxjs';
import { ApplicationDatabaseService } from 'providers/db/application-database.service';
import { flatMap, share, finalize, catchError } from 'rxjs/operators';
import { LoadingService } from '@services/loading.service';
import { Ingredient } from '@core/models/ingredient';

@Injectable({
    providedIn: 'root'
})
export class BatchApiService extends GenericAPI {

    constructor(
        private http: HttpClient,
        public applicationDatabaseService: ApplicationDatabaseService,
        private loadingService: LoadingService
    ) {
        super(applicationDatabaseService);
    }

    public getBatch(date: string, token?: string): Observable<BatchElement[]> {
        this.loadingService.openLoading();
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {


                if (token) {
                    defaultHeaders = defaultHeaders
                        .set('token', token);
                }

                const params = new HttpParams()
                    .set('date', date);

                return this.http.get<BatchElement[]>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.BATCH}`,
                    {
                        params,
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public getIngredients(date): Observable<Ingredient[]> {
        this.loadingService.openLoading();
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                const params = new HttpParams()
                    .set('date', date);

                return this.http.get<Ingredient[]>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.COMPOUND_INGREDIENTS}`,
                    {
                        params,
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

}
