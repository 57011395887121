import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WeeklyMenuComponent } from './weekly-menu.component';
import { Shell } from '@services/shell.service';
import { AuthGuardRouteService } from 'providers/guards/auth-guard.service';
import { AuxiliarDownloadOptionsShellComponent } from '@components/auxiliar-download-options-shell/auxiliar-download-options-shell.component';


const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'weekly-menu',
      component: WeeklyMenuComponent,
      canActivate: [AuthGuardRouteService],
      data: {
        title: 'Menú semanal',
        shellOptions: {
          hasBackButton: false,
          hasDateToolbar: true,
          hasBottomMenu: true,
          auxiliarOptionsComponent: AuxiliarDownloadOptionsShellComponent
        }
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})

export class WeeklyMenuRoutingModule { }
