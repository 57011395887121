// Core components
import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    AfterViewInit,
    ViewEncapsulation,
    Inject
} from '@angular/core';
import { FormControl } from '@angular/forms';
// rx
import {fromEvent, merge, Observable, Subject, Subscription} from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
// Services
import { RecipesService } from '@services/recipes.service';
import { UserService } from '@services/user.service';
// Models
import { Recipe } from '@models/recipe';
import { MatOption } from '@angular/material/core';
import { Location } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaChange, MediaObserver} from "@angular/flex-layout";
import {CUSTOM_BREAKPOINTS_KEYS} from "@constants/customBreakpoints.constants";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Options {
    icon: string;
    color?: string;
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-swap-recipe-weekly-dialog',
    templateUrl: './swap-recipe-weekly-dialog.component.html',
    styleUrls: ['./swap-recipe-weekly-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SwapRecipeWeeklyDialogComponent implements OnInit, AfterViewInit, OnDestroy {

    private autoCompleteSubject: Subject<MatOption>;
    private isMobile: boolean;
    private mediaSubscription: Subscription;

    @ViewChild('autocompleteInput', { static: false })
    public autocompleteInput: ElementRef;

    public recipes: Recipe[];
    public searchByControl = new FormControl();
    public filteredBy: string;
    public orderedBy: string;
    public searchBy: string;
    public duration: string;
    public isVegan: boolean;
    public page: number;
    public itemsPerPage = 36;
    public isFiltered = 'all';
    public isFavFiltered = false;
    public isOrdered = '';
    public sliderValue = 180;

    public users$: Subscription;
    public options: string[];
    public filteredOptions: Observable<string[]>;

    public mealType: string;
    public isSwitchRecipe: boolean;

    public meals: Options[];

    public orders: Options[];

    constructor(
        public dialogRef: MatDialogRef<SwapRecipeWeeklyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private recipesService: RecipesService,
        private mediaObserver: MediaObserver,
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService
    ) {
        this.mealType = this.data.from;
        this.isSwitchRecipe = this.data.isSwitchRecipe;

        this.autoCompleteSubject = new Subject();

        this.searchBy = '';
        this.filteredBy = this.mealType === '' ? '' : this.mealType;
        this.isFiltered = this.mealType === '' ? 'all' : this.mealType;
        this.orderedBy = '';
        this.duration = '';

        this.recipes = [];

        this.meals = [
            { icon: 'Todas_las_recetas', color: 'dark-green', value: 'all', viewValue: 'Todas las recetas' },
            { icon: 'is_breakfast', color: 'dark-cyan', value: 'breakfast', viewValue: 'Desayuno' },
            { icon: 'is_lunch', color: 'dark-pink', value: 'lunch', viewValue: 'Comida' },
            { icon: 'is_snack', color: 'light-orange', value: 'snack', viewValue: 'Merienda' },
            { icon: 'is_dinner', color: 'soft-red', value: 'dinner', viewValue: 'Cena' }
        ];

        this.orders = [
            { icon: 'Bonus', color: 'light-orange', value: 'rating', viewValue: 'Mejor valoración' },
            { icon: 'Tiempo_de_preparacion', color: 'dark-cyan', value: 'time', viewValue: 'Tiempo de preparación' }
        ];
    }

    public ngOnInit() {
        this.page = this.getPageNumber();
        this.users$ = this.userService.getMe().subscribe( (user) => {
            this.isVegan = user.vegan;
        });
        this.getRecipes();
        this.filteredOptions = this.searchByControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );
        this.mediaSubscription = this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
            if (changes) {
                this.isMobile = !!changes.filter((change) => {
                    return change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.LT_SM ||
                        change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.SM || change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.XS;
                }).length;
            }
            this.dialogRef.updateSize('100vw', '100vh');
        });
    }

    public resetToDefaultRecipe() {
        this.mediaSubscription.unsubscribe();
        this.dialogRef.close({ recipeId: 0, behaviour: 'SET_DEFAULT'} );
    }

    public selectRecipe( recipeId: number ) {
        this.mediaSubscription.unsubscribe();
        this.dialogRef.close({ recipeId: recipeId, behaviour: 'CHANGE_RECIPE'} );
    }

    public closeDialog() {
        this.close();
    }

    private close() {
        this.mediaSubscription.unsubscribe();
        this.dialogRef.close();
    }

    public ngOnDestroy() {
        this.mediaSubscription.unsubscribe();
    }

    public ngAfterViewInit() {
        const inputObserver = fromEvent(this.autocompleteInput.nativeElement, 'keyup');
        merge(inputObserver, this.autoCompleteSubject.asObservable()).pipe(
            map((event: any) => {
                return event.target ? event.target.value : event;
            }),
            filter((value: string) => {
                return !value.length || value.length > 3;
            })
        ).subscribe((value) => {
            this.onSearchChange(value);
        });
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }

    public resetFilters(): void {
        this.filteredBy = this.mealType === '' ? '' : this.mealType;
        this.isFiltered = this.mealType === '' ? 'all' : this.mealType;
        this.isFavFiltered = false;
        this.orderedBy = '';
        this.isOrdered = '';
        this.duration = '';
        this.sliderValue = 180;
        this.search();
    }

    private async getRecipes() {
        this.options = [];
        const recipes = await this.recipesService.getRecipesBy(
            this.searchBy,
            this.filteredBy,
            this.orderedBy,
            this.isFavFiltered,
            this.duration,
            '',
            '',
            this.isVegan.toString()
        );
        if ( recipes && recipes.length ) {
            recipes.map(recipe => {
                this.recipes.push(recipe);
                this.options.push(recipe.title);
            });
        }
    }

    public onOptionSelected(event) {
        this.autoCompleteSubject.next(event.option.value);
    }

    public onSearchChange(value) {
        this.searchBy = value;
        this.search();
    }

    public filterByFav() {
        this.isFavFiltered = !this.isFavFiltered;
        this.search();
    }

    public onMealChange(value) {
        if (this.mealType !== '') {
            return;
        }
        this.filteredBy = value;
        this.isFiltered = value;
        this.search();
    }

    public onOrderChange(value) {
        this.orderedBy = value;
        this.isOrdered = value;
        this.search();
    }

    public onSliderChange(event) {
        this.duration = event.value;
        this.search();
    }

    public handlePageChange(event) {
        this.page = event;
        this.router.navigate([], {
            queryParams: {
                page: event
            },
            queryParamsHandling: 'merge',
        }).then( () => this.isMobile ? window.scroll(0, 0) : document.querySelector('.mat-drawer-container').scroll(0, 0) );
    }

    public getPageNumber() {
        this.route.queryParams.subscribe(
            (params: any) => {
                this.page = params['page'] || 1;
            }
        );
        return this.page;
    }

    public resetPageNumber() {
        this.router.navigate([], {
            queryParams: {
                page: 1
            },
            queryParamsHandling: 'merge',
        });
    }

    public async search() {
        this.resetPageNumber();
        this.recipes = await this.recipesService.getRecipesBy(
            this.searchBy,
            this.filteredBy,
            this.orderedBy,
            this.isFavFiltered,
            this.duration,
            '',
            '',
            this.isVegan.toString()
        );
    }

}
