import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeeklyMenuComponent } from './weekly-menu.component';
import { WeeklyMenuRoutingModule } from './weekly-menu-routing.module';
import { MaterialModule } from '@app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NoContentModule } from '@components/no-content/no-content.module';
import { AuxiliarDownloadOptionsShellModule } from '@components/auxiliar-download-options-shell/auxiliar-download-options-shell.module';
import { RecipeNotAllowedDialogModule } from '@components/recipe-not-allowed-dialog/recipe-not-allowed-dialog.module';
import { SwapRecipeWeeklyDialogModule } from '@components/swap-recipe-weekly-dialog/swap-recipe-weekly-dialog.module';
import { AdminBarModule } from '@components/admin-bar/admin-bar.module';
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
    imports: [
        CommonModule,
        WeeklyMenuRoutingModule,
        AdminBarModule,
        MaterialModule,
        FlexLayoutModule,
        AuxiliarDownloadOptionsShellModule,
        NoContentModule,
        RecipeNotAllowedDialogModule,
        SwapRecipeWeeklyDialogModule,
        MatMenuModule,
    ],
  declarations: [WeeklyMenuComponent]
})
export class WeeklyMenuModule { }
