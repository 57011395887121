// Basic
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

// Modules
import { MaterialModule } from '@app/material.module';

// Components
import { NoPlanDialogComponent } from './no-plan-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
  ],
  declarations: [NoPlanDialogComponent],
  exports: [NoPlanDialogComponent],
  entryComponents: [NoPlanDialogComponent]
})
export class NoPlanDialogModule { }
