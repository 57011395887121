import { ENDPOINTS } from '@constants/endpoints.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericAPI } from 'providers/api/generic-api.service';
import { Observable, from, throwError } from 'rxjs';
import { environment } from '@env';
import { ApplicationDatabaseService } from 'providers/db/application-database.service';
import { flatMap, share, finalize, catchError } from 'rxjs/operators';
import { LoadingService } from '@services/loading.service';
import { Category } from '@core/models/category';

@Injectable({
    providedIn: 'root'
})
export class ShoppingListApiService extends GenericAPI {

    constructor(
        private http: HttpClient,
        public applicationDatabaseService: ApplicationDatabaseService,
        private loadingService: LoadingService
    ) {
        super(applicationDatabaseService);
    }

    public getList(date: string, order: string, token?: string): Observable<Category[]> {
        this.loadingService.openLoading();
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                if (token) {
                    defaultHeaders = defaultHeaders
                        .set('token', token);
                }

                const params = new HttpParams()
                    .set('date', date)
                    .set('group', order);

                return this.http.get<Category[]>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.LIST}`,
                    {
                        headers: defaultHeaders,
                        params
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );

    }

    public setBought(ingredient_id: number, menu_id: number): Observable<any> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded');

                const body = new HttpParams()
                    .set('ingredient_id', ingredient_id.toString())
                    .set('menu_id', menu_id.toString());

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.INGREDIENTS}`,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public unsetBought(ingredientId: number, menuId: number): Observable<any> {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.delete(
                    `${environment.rest.baseUrl}/${ENDPOINTS.INGREDIENTS}/${ingredientId}/${menuId}`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }
}
