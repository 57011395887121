import {Driver, NgForageOptions} from 'ngforage';
// Constants
import {DATABASE_CONFIG} from '@constants/storage.constants';

/**
 * Configuraton for the storage service
 */
export const applicationDatabaseConfig = {
    databaseConfig: {
        name: `${DATABASE_CONFIG.NAME}_v${DATABASE_CONFIG.VERSION}.${DATABASE_CONFIG.SUFFIX}`,
        storeName: '_application',
        driver: [
            Driver.INDEXED_DB,
            Driver.LOCAL_STORAGE
        ]
    } as NgForageOptions,
    encryption: true
};
