// Basic
import { Component, Inject } from '@angular/core';
import { Logger } from '@services/logger.service';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

const log = new Logger('snack-bar-general-error')

/**
 * Dapp-options-shell component
 */
@Component({
  selector: 'app-snack-bar-general-error',
  templateUrl: 'snack-bar-general-error.component.html',
  styleUrls: ['snack-bar-general-error.component.scss']
})
export class SnackBarGeneralErrorComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackRef: MatSnackBarRef<SnackBarGeneralErrorComponent>
  ) { }

  public close() {
    this.snackRef.dismiss();
  }
}
