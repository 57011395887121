export const cookieConfig = {
    cookie: {
      domain: "menu.clubmenusaludable.com"
    },
    position: "bottom",
    theme: "block",
    type: "info",
    palette: {
      popup: {
        background: "rgba(255,255,255,0.95)",
        text: "#454545",
        link: "#8c5775"
      },
      button: {
        background: "#8c5775",
        text: "#ffffff",
        border: "transparent",
      }
    },
    content: {
      message: "Este sitio web utiliza cookies para mejorar su experiencia de navegación y nuestros servicios.\nEl hecho de continuar navegando implica la aceptación de su uso.",
      dismiss: "¡Lo entiendo!",
      deny: "Rechazar cookies",
      link: "Más información.",
      href: "/cookies-policy",
      policy: "Cookie Policy"
    },
  };
