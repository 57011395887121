// Basic
import {Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';
import {User} from "@models/user";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {DateAdapter} from "@angular/material/core";

@Component({
    selector: 'app-admin-bar',
    templateUrl: 'admin-bar.component.html',
    styleUrls: ['admin-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AdminBarComponent implements OnInit {

    @Input() user: User;
    @Output() updateWeekDate = new EventEmitter<Date>();

    constructor(
        private dateAdapter: DateAdapter<Date>
    ) { }

    public ngOnInit() {
        this.dateAdapter.setLocale('es');
        this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
    }

    public updateWeek(type: string, event: MatDatepickerInputEvent<Date>) {
        this.updateWeekDate.emit(event.value);
    }

}
