import {ENDPOINTS} from '@constants/endpoints.constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GenericAPI} from './generic-api.service';
import {environment} from '@env';
import {from, throwError} from 'rxjs';
import {ApplicationDatabaseService} from 'providers/db/application-database.service';
import {flatMap, share, finalize, catchError} from 'rxjs/operators';
import {LoadingService} from '@services/loading.service';
import {User} from '@core/models/user';

@Injectable({
    providedIn: 'root'
})
export class UsersApiService extends GenericAPI {

    constructor(
        private http: HttpClient,
        public applicationDatabaseService: ApplicationDatabaseService,
        private loadingService: LoadingService
    ) {
        super(applicationDatabaseService);
    }


    public getMe() {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.get<User>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.GET_ME}`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        )
    }

    public userAlreadyExists(email) {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                return this.http.get<any>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.USER_ALREADY_EXISTS}/${email}`,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        )
    }

    public updateUser(userId, params) {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                defaultHeaders = defaultHeaders
                    .append('Content-Type', 'application/x-www-form-urlencoded');

                let body = new HttpParams();

                for (let param of params) {
                    body = body.append(param.key, param.value);
                }

                return this.http.put<User>(
                    `${environment.rest.baseUrl}/${ENDPOINTS.USERS}/${userId}`,
                    body.toString(),
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        )
    }

    public uploadAvatar(image: File) {
        this.loadingService.openLoading(false);
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                const body = new FormData();

                body.append('file', image);

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.FILES}/avatar`,
                    body,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    finalize(() => this.loadingService.closeLoading()),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

    public linkUserToAffiliatly(userId, params) {
        return from(this.getGenericHeaders()).pipe(
            flatMap((defaultHeaders) => {

                const body = new FormData();
                body.append('user_id', userId);
                body.append('affiliatly_id_user', params.id_user);
                body.append('affiliatly_id_token', params.id_token);
                body.append('affiliatly_aff_uid', params.aff_uid);

                return this.http.post(
                    `${environment.rest.baseUrl}/${ENDPOINTS.USERS}/affiliatly/link`,
                    body,
                    {
                        headers: defaultHeaders
                    }
                ).pipe(
                    share(),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            })
        );
    }

}
