// Core components
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
// Services
import { DateServices } from '@services/date.service';
import { MenusService } from '@services/menus.service';
// Rx
import { Subscription } from 'rxjs';
import * as moment from 'moment';
// Models
import { DailyMenu } from '@core/models/dailyMenu';
import { PDFService } from '@services/pdf.service';
import { UserService } from '@services/user.service';
import { Subscription as UserSubscription } from '@core/models/subscriptionStatus';
import { User } from '@core/models/user';
import { MatDialog } from '@angular/material/dialog';
import { RecipeNotAllowedDialogComponent } from '@components/recipe-not-allowed-dialog/recipe-not-allowed-dialog.component';
import { Recipe } from '@core/models/recipe';
import { RecipesService } from '@services/recipes.service';
import { Ingredient } from '@core/models/ingredient';
import { ShoppingListService } from '@services/shopping-list.service';
import { LoadingService } from '@services/loading.service';
import { BatchService } from '@services/batch.service';
import { BatchElement } from '@core/models/batchElement';
import { MatSelectChange } from '@angular/material/select';
import { Category } from '@core/models/category';
import {ActivatedRoute} from "@angular/router";
import {Moment} from "moment";
import { ApplicationDatabaseService } from 'providers/db/application-database.service';
import {DATABASE_KEYS} from "@constants/storage.constants";
import {SwapRecipeWeeklyDialogComponent} from "@components/swap-recipe-weekly-dialog/swap-recipe-weekly-dialog.component";

@Component({
    selector: 'app-weekly-menu',
    templateUrl: './weekly-menu.component.html',
    styleUrls: ['./weekly-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class WeeklyMenuComponent implements OnInit, OnDestroy {

    public week: string;
    public weekMenu: DailyMenu[];
    private weekRaw: Moment;
    private list: Category[];
    private ingredients: Ingredient[];
    private batch: BatchElement[];

    private date$: Subscription;
    private user$: Subscription;
    public user: User;

    public role: UserSubscription.Role;
    public isImpersonation: boolean;
    public allowSnacks: boolean;
    public snack_included: boolean;
    public breakfast_included: boolean;

    private numDinersPDF: number;

    public numPersons = [...Array(10).keys()].map((e, i) => ++i);

    constructor(
        private activatedRoute: ActivatedRoute,
        private menusService: MenusService,
        private dateService: DateServices,
        private pdfService: PDFService,
        private userService: UserService,
        private loadingService: LoadingService,
        private applicationDatabaseService: ApplicationDatabaseService,
        private dialog: MatDialog,
        private recipesService: RecipesService,
        private shoppingListService: ShoppingListService,
        private batchService: BatchService
    ) { }

    public async ngOnInit() {

        this.date$ = this.dateService.getWeekObserver().subscribe(async (week) => {
            this.week = this.dateService.getFormattedWeek();
            this.weekRaw = week;
            this.weekMenu = await this.menusService.getMenu(week.format('YYYY-MM-DD'));
            this.list = await this.shoppingListService.getList(week.format('YYYY-MM-DD'), 'CATEGORY');
            this.ingredients = await this.batchService.getIngredients(week.format('YYYY-MM-DD'));
            this.batch = await this.batchService.getBatch(week.format('YYYY-MM-DD'));
        });

        this.user$ = this.userService.getMe().subscribe(user => {
            this.breakfast_included = user.breakfast_included;
            this.snack_included = user.snack_included;
            this.role = user.subscription.metadata["plan-role"];
            this.allowSnacks = user.subscription.metadata["allow-snacks"] === 'true';
            this.user = user;
            this.numDinersPDF = user.num_diners;
        });

        this.isImpersonation = await this.applicationDatabaseService.get(DATABASE_KEYS.impersonation).toPromise();

    }

    public ngOnDestroy() {
        this.date$.unsubscribe();
        this.user$.unsubscribe();
    }

    public setWeek(event: Date) {
        this.dateService.setWeek(moment(event));
    }

    public managePlanDialog() {
        const dialog = this.dialog.open(RecipeNotAllowedDialogComponent, {
            maxWidth: '100vw',
            panelClass: 'fullscreen-dialog',
            disableClose: true,
            autoFocus: false,
            hasBackdrop: true,
            data: {
                name: this.user.first_name
            }
        })
    }

    public changeRecipeMenu(event: Event, recipe: Recipe, from: string, menuId: string, fromNew: boolean = false) {
        event.stopPropagation();
        if (this.user.is_old || this.role !== 'BASIC') {

            const dialog = this.dialog.open(SwapRecipeWeeklyDialogComponent, {
                maxWidth: '100vw',
                panelClass: 'fullscreen-dialog',
                disableClose: true,
                autoFocus: false,
                hasBackdrop: false,
                data: {
                    from,
                    fromNew
                }
            });

            dialog.afterClosed().subscribe(async result => {
                if (result > 0) {
                    await this.recipesService.changeRecipeMenu(menuId, from, result.recipeId, result.behaviour);
                    this.weekMenu = await this.menusService.getMenu(this.weekRaw.format('YYYY-MM-DD'));
                } else if (result === -1) {
                    await this.recipesService.changeRecipeMenu(menuId, from, result.recipeId, result.behaviour);
                    this.weekMenu = await this.menusService.getMenu(this.weekRaw.format('YYYY-MM-DD'));
                } else {
                    await this.recipesService.changeRecipeMenu(menuId, from, result.recipeId, result.behaviour);
                    this.weekMenu = await this.menusService.getMenu(this.weekRaw.format('YYYY-MM-DD'));
                }
                this.list = await this.shoppingListService.getList(this.weekRaw.format('YYYY-MM-DD'), 'CATEGORY');
                this.ingredients = await this.batchService.getIngredients(this.weekRaw.format('YYYY-MM-DD'));
                this.batch = await this.batchService.getBatch(this.weekRaw.format('YYYY-MM-DD'));
            });
        } else {
            this.managePlanDialog();
        }
    }

    public async deleteRecipe(from: string, menuId: string, recipeId: number = -1, behaviour: string = 'SET_NULL' ) {
        await this.recipesService.changeRecipeMenu(menuId, from, recipeId, behaviour);
        this.weekMenu = await this.menusService.getMenu(this.weekRaw.format('YYYY-MM-DD'));
    }

    public async dinnersChange(event: MatSelectChange, recipe: Recipe, from: string, menuId: string, behaviour: string = 'CHANGE_RECIPE') {
        await this.recipesService.changeNumDinners(event.value, menuId, from, recipe.id, behaviour);
        this.weekMenu = await this.menusService.getMenu(this.weekRaw.format('YYYY-MM-DD'));
    }

    public async download() {
        this.loadingService.openLoading(false);
        await new Promise(resolve => setTimeout(resolve, 200));
        if (this.user.is_old || this.role !== 'BASIC') {
            const recipes: Recipe[] = [];

            this.weekMenu.forEach(day => {
                if (day.breakfast) {
                    recipes.push(day.breakfast);
                }
                if (day.lunch_starter) {
                    recipes.push(day.lunch_starter);
                }
                if (day.lunch) {
                    recipes.push(day.lunch);
                }
                if (day.snack) {
                    recipes.push(day.snack);
                }
                if (day.dinner_starter) {
                    recipes.push(day.dinner_starter);
                }
                if (day.dinner) {
                    recipes.push(day.dinner);
                }
            });
            recipes.forEach( (recipe) => {
                recipe.ingredients_main_preparation = this.recipesService.groupByIngredients(recipe.ingredients_main_preparation);
                recipe.ingredients_previous_preparation = this.recipesService.groupByIngredients(recipe.ingredients_previous_preparation);
            });
            this.pdfService.downloadAll(recipes, this.numDinersPDF, this.weekMenu, this.week, this.user, this.list, this.ingredients, this.batch);
        } else {
            this.pdfService.downloadWeeklyMenu(this.weekMenu, this.week, this.user);
        }
        this.loadingService.closeLoading();
    }

}
