// Core dependencies
import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules, Routes } from '@angular/router';

// Services
import { Shell } from '@services/shell.service';

const routes: Routes = [
  {
    path: 'migration',
    loadChildren: () => import('@pages/migration-landing/migration-landing.module').then(m => m.MigrationLandingModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('@pages/welcome/welcome.module').then(m => m.WelcomeModule)
  },
  {
    path: 'login',
    loadChildren: () => import('@pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('@pages/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'preview/:id',
    loadChildren: () => import('pages/recipe/recipe.module').then(m => m.RecipeModule)
  },
  {
    path: 'preview-shopping-list/:id',
    loadChildren: () => import('@pages/list/list.module').then(m => m.ListModule)
  },
  {
    path: 'preview-batch-cooking/:id',
    loadChildren: () => import('@pages/batch/batch.module').then(m => m.BatchModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('@pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('@pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('@pages/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'no-plan',
    loadChildren: () => import('@pages/no-plan/no-plan.module').then(m => m.NoPlanModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('@pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'cookies-policy',
    loadChildren: () => import('@pages/cookies-policy/cookies-policy.module').then(m => m.CookiesPolicyModule)
  },
  {
    path: 'legal-warning',
    loadChildren: () => import('@pages/legal-warning/legal-warning.module').then(m => m.LegalWarningModule)
  },
  {
    path: 'sale-conditions',
    loadChildren: () => import('@pages/sale-conditions/sale-conditions.module').then(m => m.SaleConditionsModule)
  },
  Shell.childRoutes([
    {
      path: 'list',
      loadChildren: () => import('@pages/list/list.module').then(m => m.ListModule)
    },
    {
      path: 'batch',
      loadChildren: () => import('@pages/batch/batch.module').then(m => m.BatchModule)
    },
    {
      path: 'recipes',
      loadChildren: () => import('@pages/recipes/recipes.module').then(m => m.RecipesModule)
    },
    {
      path: 'recipes/:id',
      loadChildren: () => import('@pages/recipe/recipe.module').then(m => m.RecipeModule)
    },
    {
      path: 'profile',
      loadChildren: () => import('@pages/profile/profile.module').then(m => m.ProfileModule)
    },
    {
      path: 'subscription',
      loadChildren: () => import('@pages/subscription/subscription.module').then(m => m.SubscriptionModule)
    },
    {
      path: 'preferences',
      loadChildren: () => import('@pages/preferences/preferences.module').then(m => m.PreferencesModule)
    }
  ]),

  // Whether the user tries to go to another route.
  { path: "**", redirectTo: 'weekly-menu', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
