
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell.component';
import { MaterialModule } from '@app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuxiliarDownloadOptionsShellModule } from '@components/auxiliar-download-options-shell/auxiliar-download-options-shell.module';
import { AuxiliarSearchOptionsShellModule } from '@components/auxiliar-search-options-shell/auxiliar-search-options-shell.module';
import { DirectivesModule } from '@directives/directives.module';
import { MobileBottomMenuComponentModule } from '@components/mobile-bottom-menu/mobile-bottom-menu.module';
import { MobileSubmenuDialogModule } from '@components/mobile-submenu-dialog/mobile-submenu-dialog.module';
import { FooterModule } from '@components/footer/footer.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    AuxiliarDownloadOptionsShellModule,
    AuxiliarSearchOptionsShellModule,
    DirectivesModule,
    MobileBottomMenuComponentModule,
    MobileSubmenuDialogModule,
    FooterModule
  ],
  declarations: [ShellComponent]
})
export class ShellModule { }
