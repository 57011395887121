export const DATABASE_KEYS = {
    userId: 'userId',
    userToken: 'token',
    role: 'role',
    userType: 'userType',
    avatar: 'avatar',
    numKids: 'numKids',
    numAdults: 'numAdults',
    intolerance: 'intolerance',
    cookies: 'cookies',
    breakfastIncluded: 'breakfastIncluded',
    snakIncluded: 'snakIncluded',
    user: 'user',
    subscription: 'subscription',
    impersonation: 'impersonation',
    registrationToken: 'registrationToken'
};

export const DATABASE_CONFIG = {
    NAME: 'venu_sanz_database',
    VERSION: '2',
    SUFFIX: 'db'
};
