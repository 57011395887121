import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApplicationDatabaseService } from '@db/application-database.service';
import { DATABASE_KEYS } from '@core/constants/storage.constants';
import { map } from 'rxjs/operators';
import { ProfileDatabaseService } from '@db/profile-database.service';

import { NgForage } from 'ngforage';
import { Subscription } from '@core/models/subscriptionStatus';
import { MatDialog } from '@angular/material/dialog';
import { NoPlanDialogComponent } from '@components/no-plan-dialog/no-plan-dialog.component';
import { User } from '@core/models/user';
import { OnboardingDialogComponent } from '@components/onboarding-dialog/onboarding-dialog.component';
import { PausedPlanDialogComponent } from '@components/paused-plan-dialog/paused-plan-dialog.component';
import {StripeService} from "@services/stripe.service";
import { LoadingService } from '@services/loading.service';
import {environment} from "@env";

@Injectable({ providedIn: 'root' })
export class AuthGuardRouteService implements CanActivate {

    constructor(
        private router: Router,
        private applicationDatabaseService: ApplicationDatabaseService,
        private profileDatabaseService: ProfileDatabaseService,
        private ngForage: NgForage,
        private matDialog: MatDialog,
        private stripeService: StripeService,
        private loadingService: LoadingService
    ) { }

    public async canActivate(route: any) {
        await this.ngForage.ready();
        const isAuthenticated = await this.applicationDatabaseService.get(DATABASE_KEYS.userToken).pipe(
            map(token => !!token)
        ).toPromise();

        if (route._routerState.url.includes('preview')) {
            return true;
        }

        this.loadingService.openLoading(false);

        if (isAuthenticated) {
            const subscription: Subscription = await this.profileDatabaseService.get(DATABASE_KEYS.subscription).toPromise();
            if (subscription) {
                if (subscription.subscription_status === Subscription.SubscriptionStatus.ACTIVE || subscription.subscription_status === Subscription.SubscriptionStatus.TRIALING ) {
                    const user: User = await this.profileDatabaseService.get(DATABASE_KEYS.user).toPromise();
                    if (user && !user.profile_completed) {
                        await this.showOnboarding();
                        console.log('redirect');
                        this.redirectToWordpress();
                        return true;
                    }
                    if (subscription.paused) {
                        this.dialogPausedPlan();
                        return false;
                    }
                    return true;
                }
                if (subscription.pending_setup_intent != null) {
                  const {client_secret, status} = subscription.pending_setup_intent;
                  if (status === 'requires_action') {
                    const {setupIntent, error} = await this.stripeService.getStripe().confirmCardSetup(client_secret);
                    if (error) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                }
                this.dialogNoPlanActive();
                return false;
            }
            return false;
        }
        this.loadingService.closeLoading();
        this.router.navigate(['']);
        return false;

    }

  protected redirectToWordpress() {
    window.setTimeout( () => {
        window.location.href = environment.urlWordpress ;
    }, 10);
   }


    private dialogNoPlanActive() {
        this.matDialog.open(NoPlanDialogComponent, {
            maxWidth: '100vw',
            panelClass: 'fullscreen-dialog',
            disableClose: true,
            autoFocus: false,
            hasBackdrop: true,
        });
    }

    private dialogPausedPlan() {
        this.matDialog.open(PausedPlanDialogComponent, {
            maxWidth: '100vw',
            panelClass: 'fullscreen-dialog',
            disableClose: true,
            autoFocus: false,
            hasBackdrop: true,
        });
    }

    private async showOnboarding() {
        const dialog = this.matDialog.open(OnboardingDialogComponent, {
            maxWidth: '100vw',
            panelClass: 'fullscreen-dialog',
            disableClose: true,
            autoFocus: false,
            hasBackdrop: true,
        });

        return dialog.afterClosed().toPromise();
    }
}
