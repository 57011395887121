// Core components
import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.html',
    styleUrls: ['./progress-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ProgressBarComponent {

    @Input() public isLoading: boolean;

    constructor() {
    }

}
