export interface Subscription {
    allow_trial: boolean;
    cancel_at_period_end: boolean;
    card_brand: string;
    current_period_end: number;
    current_period_start: number;
    permanence_until: number;
    trial_end: number;
    last4: string;
    plan_id: string;
    stripe_subscription_id: string;
    subscription_status: Subscription.SubscriptionStatus;
    exp_month: string;
    exp_year: string;
    subscriptionDate?: string;
    funding: string;
    week_offset_future: number;
    week_offset_past: number;
    metadata: Subscription.Metadata;
    payment_provider: string;
    paused: boolean;
    resumes_at: number;
    disable_cancel: boolean;
    pending_setup_intent?: Subscription.SetupIntent;
    scheduled_trial_end: number;
    scheduled_trial_start: number;
    customer_balance: number;
    next_pending_invoice_item_invoice?: number;
}

export namespace Subscription {
    export enum SubscriptionStatus {
        INCOMPLETE = 'incomplete',
        INCOMPLETE_EXPIRED = 'incomplete_expired',
        TRIALING = 'trialing',
        ACTIVE = 'active',
        PAST_DUE = 'past_due',
        CANCELLED = 'canceled',
        UNPAID = 'unpaid'
    }

    export interface Metadata {
        'plan-role': Role;
        visible: string | boolean;
        discount: string;
        title: string;
        bonus: string;
    }

    export enum Role {
        BASIC = 'BASIC',
        REGULAR = 'REGULAR',
        ADVANCED = 'ADVANCED'
    }

    export interface  SetupIntent {
      id: number;
      client_secret: string;
      message: string;
      status: string;
    }
}
