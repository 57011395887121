// Basic
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

// Modules
import { MaterialModule } from '@app/material.module';

// Components
import { PausedPlanDialogComponent } from './paused-plan-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
  ],
  declarations: [PausedPlanDialogComponent],
  exports: [PausedPlanDialogComponent],
  entryComponents: [PausedPlanDialogComponent]
})
export class PausedPlanDialogModule { }
