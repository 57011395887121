export const CATEGORIES = {
    aceites: {
        name: 'Aceites',
        icon: 'venu-aceites'
    },
    verduras: {
        name: 'Verduras',
        icon: 'venu-verduras'
    },
    frutas: {
        name: 'Frutas',
        icon: 'venu-frutas'
    },
    cereales: {
        name: 'Cereales',
        icon: 'venu-cereales'
    },
    bebidas: {
        name: 'Bebidas',
        icon: 'venu-bebidas'
    },
    salsas: {
        name: 'Salsas',
        icon: 'venu-salsas'
    },
    legumbres: {
        name: 'Legumbres',
        icon: 'venu-legumbres'
    },
    frutos_secos: {
        name: 'Frutos Secos',
        icon: 'venu-frutossecos'
    },
    semillas: {
        name: 'Semillas',
        icon: 'venu-semillas'
    },
    condimentos: {
        name: 'Despensa',
        icon: 'venu-condimentos'
    },
    hierbas: {
        name: 'Hierbas',
        icon: 'venu-hierbas'
    },
    especias: {
        name: 'Especias',
        icon: 'venu-especias'
    },
    pescado_huevos: {
        name: 'Pescado - Huevos',
        icon: 'venu-flexible'
    },
    otros: {
        name: 'Otros',
        icon: 'venu-otros'
    },
}
