export const ENDPOINTS = {
    USERS: 'users',
    USER: 'user',
    MENU: 'menus/week',
    RECIPE_DETAIL: 'menus/recipes',
    RECIPES: 'recipes',
    RECIPES_BY: 'recipes/search',
    ALLERGENS: 'allergens',
    LOGIN: 'users/sessions',
    LOGOUT: 'users/sessions/token',
    REGISTER: 'users',
    ADD_PAYMENT_SUBSCRIPTION: 'payments/customers',
    CREATE_PAYMENT_PLAN: 'payments/subscriptions',
    LIST: 'shopping_list',
    INGREDIENTS: 'shopping_list',
    BATCH: 'batch_cooking',
    COMPOUND_INGREDIENTS: 'batch_cooking/ingredients',
    FILES: 'files',
    STRIPE_INTENT: 'payments/intent',
    PAYMENT_STATUS: 'payments/status',
    PAYMENT_PLANS: 'services',
    PRODUCTS_CONFIG: 'products_config',
    GET_ME: 'users/current',
    IS_MIGRATED: 'users/old_subscriptions',
    MIGRATION: 'users/subscriptions/migration',
    CONFIG: 'config',
    RECIPE_REPLACEMENT: 'menus/recipe_replacement',
    TESTIMONIALS: 'testimonials',
    PAUSE_RESUME_SUBSCRIPTION: 'payments/subscriptions/behaviour',
    USER_ALREADY_EXISTS: 'users/email',
    CANCELLATION_GIFTS: 'cancellation_gifts'
};
