import { Injectable } from '@angular/core';
import { BatchApiService } from '@api/batch-api.service';
import { BatchElement } from '@core/models/batchElement';
import { Ingredient } from '@core/models/ingredient';
import { map } from 'rxjs/operators';
import { BatchStep } from '@core/models/batchStep';

@Injectable({ providedIn: 'root' })
export class BatchService {
    constructor(
        private batchApiService: BatchApiService
    ) { }

    public getBatch(week: string, token?: string): Promise<BatchElement[]> {
        return this.batchApiService.getBatch(week, token).pipe(
            map((batchElements: BatchElement[]) => { // Calculate the quantity for 1 person.
                if (batchElements) {
                    return batchElements.map(batchElement => {
                        if (batchElement.batch_steps) {
                            batchElement.batch_steps.map(step => {
                                step.ingredients.map(ingredient => {
                                    //TODO: change this 4 to num of recipe people
                                    ingredient.qty = ingredient.qty / 4;
                                });
                            });
                        }
                        return batchElement;
                    });
                }
                return batchElements;
            }),
            map((batchElements: BatchElement[]) => { // Filter to avoid duplicated steps.
                if (batchElements) {
                    return batchElements.map(batchElement => {
                        if (batchElement.batch_steps && batchElement.batch_steps.length) {
                            setTimeout(() => { // Improve the feedback of the user refreshing the page.
                                batchElement.batch_steps = this.getBatchStepsFiltered(batchElement.batch_steps);
                            })
                        }
                        return batchElement;
                    });
                }
                return batchElements;
            })
        ).toPromise();
    }

    private getBatchStepsFiltered(batchSteps: BatchStep[]): BatchStep[] {
        const batchStepsFiltered = [];

        batchSteps.forEach((step: BatchStep, index: number) => {

            const isStepAlreadyChecked = batchStepsFiltered.some((stepFiltered: BatchStep) => {
                return this.isDuplicatedSteps(step, stepFiltered);
            })

            if (!isStepAlreadyChecked) {
                const duplicatedSteps = batchSteps.filter((stepFiltered: BatchStep, indexFiltered: number) => {
                    if (index !== indexFiltered) {
                        return this.isDuplicatedSteps(step, stepFiltered);
                    }
                    return false;
                });

                step.ingredients.map((ingredient) => {
                    ingredient.qty = ingredient.qty * step.num_diners;
                });

                if (duplicatedSteps.length) {
                    const concatStep = this.mergeStep(step, duplicatedSteps);
                    batchStepsFiltered.push(concatStep)
                } else {
                    batchStepsFiltered.push(step)
                }
            }
        });

        return batchStepsFiltered
    }

    private isDuplicatedSteps(step: BatchStep, stepFiltered: BatchStep): boolean {
        if (stepFiltered.title === step.title && stepFiltered.desc.replace(/\s/g, ' ') === step.desc.replace(/\s/g, ' ')) {
            const stepIngredientsNamesUnitsFromBatchStep = this.plunk(step.ingredients, 'name', 'units');
            const stepIngredientsNamesUnitsFromFilteredSteps = this.plunk(stepFiltered.ingredients, 'name', 'units');
            return JSON.stringify(stepIngredientsNamesUnitsFromBatchStep) === JSON.stringify(stepIngredientsNamesUnitsFromFilteredSteps);

        }
        return false;
    }

    private plunk(arr: any[], key: string, key2: string) {
        return arr.map(o => `${o[key]}-${o[key2]}`);
    }

    private mergeStep(step: BatchStep, duplicatedSteps: BatchStep[]): BatchStep {
        let mergedStep = { ...step };
        duplicatedSteps.forEach(duplicatedStep => {
            if (!mergedStep.used_in.includes(duplicatedStep.used_in[0])) {
                mergedStep.used_in = [...mergedStep.used_in, ...duplicatedStep.used_in];
            }

            mergedStep.ingredients.map((ingredient, index) => {
                ingredient.qty = ingredient.qty + (duplicatedStep.ingredients[index].qty * duplicatedStep.num_diners);
                return ingredient;
            });

        });
        return mergedStep;
    }

    public getIngredients(week: string): Promise<Ingredient[]> {
        return this.batchApiService.getIngredients(week).pipe(
            map(ingredients => {
                if (ingredients && ingredients.length) {
                    return ingredients.map(ingredient => {
                        if (ingredient.type === Ingredient.type.COMPOUND) {
                            if (ingredient.sub_ingredients) {
                                for (let subingredient of ingredient.sub_ingredients) {
                                    //TODO: change this 4 to num of recipe people
                                    subingredient.qty = subingredient.qty / 4;
                                }
                            } else {
                                ingredient.sub_ingredients = [];
                            }
                            if (ingredient.qty === 0) {
                                ingredient.qty = 1;
                            }
                        } else {
                            ingredient.qty = ingredient.qty / 4
                        }
                        return ingredient;
                    });
                }
                return ingredients;
            })
        ).toPromise()
    }

}
