// Core components
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from '@services/auth.service';
import { Footer } from '@core/models/footer';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { User } from '@core/models/user';
import { LoadingService } from '@services/loading.service';
import { PaymentPlanService } from '@services/payment-plan.service';
import { Club } from '@core/models/club';
import { Logger } from '@services/logger.service';
import {OnboardingDialogComponent} from "@components/onboarding-dialog/onboarding-dialog.component";

const log = new Logger('login.component');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  public footerOptions: Footer[];
  public loginForm: FormGroup;
  private quickLoginToken: string;
  private quickLoginUserId: string;
  private navigateToRoute?: string;
  public clubStatus: Club;
  public impersonation = false;

  constructor(
    private paymentPlanService: PaymentPlanService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private location: Location,
    private loadingService: LoadingService
  ) {


    this.quickLoginToken = this.activatedRoute.snapshot.queryParams['token'];
    this.quickLoginUserId = this.activatedRoute.snapshot.queryParams['userId'];
    if (this.activatedRoute.snapshot.queryParams['navigate'] !== undefined) {
      this.navigateToRoute = this.activatedRoute.snapshot.queryParams['navigate'];
    }
    this.impersonation = this.activatedRoute.snapshot.queryParams['impersonation'] !== undefined;

    this.footerOptions = [
      {
        routerLink: [''],
        text: 'Portada'
      },
      {
        text: 'Preguntas frecuentes',
        externalLink: 'https://ayuda.venusanzchef.com/'
      },
      {
        externalLink: 'https://ayuda.venusanzchef.com/',
        text: 'Contacto'
      },
      {
        routerLink: ['/sale-conditions'],
        text: 'Condiciones de Venta'
      },
      {
        routerLink: ['/privacy-policy'],
        text: 'Política de privacidad'
      },
      {
        routerLink: ['/cookies-policy'],
        text: 'Política de cookies'
      },
      {
        routerLink: ['/legal-warning'],
        text: 'Aviso legal'
      }
    ];

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

  }

  public async ngOnInit() {
    if (this.quickLoginToken && this.quickLoginUserId) {
      this.loadingService.openLoading(false);
      await this.authService.doSoftLogin(this.quickLoginToken, this.quickLoginUserId, User.USER_ROLE.REGULAR, this.navigateToRoute, this.impersonation);
      this.loadingService.closeLoading();
    }
    try {
      this.loadingService.openLoading(false);
      this.clubStatus = await this.paymentPlanService.getClubStatus();
    } catch (error) {
      log.debug(error);
    } finally {
      this.loadingService.closeLoading();
    }
  }

  public getErrorMessage(formField) {
    return formField.hasError('required') ? 'Este campo es obligatorio' :
      formField.hasError('email') ? 'Email no valido' : '';
  }

  public back() {
    this.location.back();
  }

  public doLogin() {
    this.authService.doLogin(this.loginForm.get('email').value, this.loginForm.get('password').value);
  }

  public clubClosed() {
    window.open(this.clubStatus.WAITING_LIST_URL, '_blank')
  }
}
