// Basic
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { CUSTOM_BREAKPOINTS_KEYS } from '@core/constants/customBreakpoints.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recipe-not-allowed-dialog',
  templateUrl: 'recipe-not-allowed-dialog.component.html',
  styleUrls: ['recipe-not-allowed-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecipeNotAllowedDialogComponent implements OnInit {


  private isMobile: boolean;
  private mediaSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<RecipeNotAllowedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mediaObserver: MediaObserver,
    private router: Router
  ) { }

  public ngOnInit() {
    this.mediaSubscription = this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      if (changes) {
        this.isMobile = !!changes.filter((change) => {
          return change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.LT_SM ||
            change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.SM || change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.XS;
        }).length;
        if (!this.isMobile) {
          this.dialogRef.updateSize('700px', '670px');
        } else {
          this.dialogRef.updateSize('100vw', '100vh');
        }
      }
    });
  }

  public goToChangePlan() {
    this.router.navigate(['/subscription']);
    this.close();
  }

  public closeDialog() {
    this.close();
  }

  private close() {
    this.mediaSubscription.unsubscribe();
    this.dialogRef.close();
  }
}
