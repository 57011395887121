// Basic
import { Component, OnDestroy, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { Logger } from '@services/logger.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DateServices } from '@services/date.service';
import { MenusService } from '@services/menus.service';
import { DailyMenu } from '@core/models/dailyMenu';
import { PDFService } from '@services/pdf.service';
import { ShoppingListService } from '@services/shopping-list.service';
import { UserService } from '@services/user.service';
import { Subscription as UserSubscription } from '@core/models/subscriptionStatus';
import { BatchService } from '@services/batch.service';
import { LoadingService } from '@services/loading.service';
import { BatchElement } from '@core/models/batchElement';
import { Ingredient } from '@core/models/ingredient';
import { RecipesService } from '@services/recipes.service';
import { AES, enc } from 'crypto-js';

// Environment
import { environment } from '@env';
import { User } from '@core/models/user';
import { Recipe } from '@core/models/recipe';
import { Category } from '@core/models/category';

const log = new Logger('auxiliar-download-options-shell');

/**
 * Dapp-options-shell component
 */
@Component({
    selector: 'app-auxiliar-download-options-shell',
    templateUrl: 'auxiliar-download-options-shell.component.html',
    styleUrls: ['auxiliar-download-options-shell.component.scss']
})

export class AuxiliarDownloadOptionsShellComponent implements AfterViewInit, OnDestroy {

    private route: any;
    private date$: Subscription;
    private numDiners$: Subscription;
    private weekMenu: DailyMenu[];
    private week: string;
    private list: Category[];
    private batch: BatchElement[];
    private numDiners: number;
    private ingredients: Ingredient[];
    private role: UserSubscription.Role;
    private isOld: boolean;

    private user: User;

    @ViewChild('downloadButton', { static: false, read: ElementRef }) downloadButton: ElementRef;

    constructor(
        private activatedRoute: ActivatedRoute,
        private dateService: DateServices,
        private menusService: MenusService,
        private pdfService: PDFService,
        private shoppingListService: ShoppingListService,
        private userService: UserService,
        private batchService: BatchService,
        private loadingService: LoadingService,
        private recipesService: RecipesService,
        private renderer: Renderer2
    ) {
    }

    public ngAfterViewInit() {
        this.route = (this.activatedRoute.firstChild.routeConfig || this.activatedRoute.firstChild.firstChild.routeConfig);

        this.date$ = this.dateService.getWeekObserver().subscribe(async (week) => {
            this.week = this.dateService.getFormattedWeek();

            const weekDate = week.format('YYYY-MM-DD');

            this.weekMenu = await this.menusService.getMenu(weekDate);
            this.list = await this.shoppingListService.getList(weekDate, 'CATEGORY');
            this.ingredients = await this.batchService.getIngredients(weekDate);
            this.batch = await this.batchService.getBatch(weekDate);
        });

        this.numDiners$ = this.userService.getMe().subscribe((user) => {
            this.numDiners = user.num_diners;
            this.isOld = user.is_old;
            this.role = user.subscription.metadata["plan-role"];
            this.user = user;
        });

        switch (this.route.path) {
            case 'weekly-menu': {
                if (this.isOld || this.role !== 'BASIC') {
                    this.renderer.setStyle(this.downloadButton.nativeElement, 'display', 'block');
                } else {
                    this.renderer.setStyle(this.downloadButton.nativeElement, 'display', 'none');
                }
                break;
            }

            case 'recipes/:id': {
                if (this.isOld || this.role !== 'BASIC') {
                    this.renderer.setStyle(this.downloadButton.nativeElement, 'display', 'block');
                } else {
                    this.renderer.setStyle(this.downloadButton.nativeElement, 'display', 'none');
                }
                break;
            }
        }

    }

    public ngOnDestroy() {
        if (this.date$) {
            this.date$.unsubscribe();
        }
        if (this.numDiners$) {
            this.numDiners$.unsubscribe();
        }
    }

    public async download() {
      this.loadingService.openLoading(false);
        switch (this.route.path) {
            case 'weekly-menu': {
                const recipes: Recipe[] = [];

                this.weekMenu.forEach(day => {
                    if (day.breakfast) {
                        recipes.push(day.breakfast)
                    }
                    if (day.lunch_starter) {
                        recipes.push(day.lunch_starter);
                    }
                    if (day.lunch) {
                        recipes.push(day.lunch);
                    }
                    if (day.snack) {
                        recipes.push(day.snack);
                    }
                    if (day.dinner_starter) {
                        recipes.push(day.dinner_starter);
                    }
                    if (day.dinner) {
                        recipes.push(day.dinner);
                    }
                });

                this.pdfService.downloadAll(recipes, this.numDiners, this.weekMenu, this.week, this.user, this.list, this.ingredients, this.batch);
                break;
            }

            case 'list': {
                this.pdfService.downloadShoppingList(this.list, this.week, this.numDiners);
                break;
            }

            case 'batch': {
                this.pdfService.downloadBatchCooking(this.ingredients, this.batch, this.week);
                break;
            }
            case 'recipes/:id': {
                const token = this.decode(this.activatedRoute.firstChild.snapshot.queryParams['token']) || this.activatedRoute.firstChild.snapshot.queryParams['token'];
                const recipe = await this.recipesService.getRecipe(this.activatedRoute.firstChild.snapshot.params['id'], token);
                this.pdfService.downloadRecipe(recipe, this.numDiners);
                break;
            }
        }
        this.loadingService.closeLoading();
    }

    private decode(cipherText) {
        if (cipherText) {
            const reb64 = enc.Hex.parse(cipherText);
            const bytes = reb64.toString(enc.Base64);
            const decrypt = AES.decrypt(bytes, environment.rest.secret);
            return decrypt.toString(enc.Utf8);
        }
    }

}
