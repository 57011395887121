import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EventTrackerService {

    constructor() { }

    public GTMAddProductEvent(payment, variant) {
        if ( typeof (window as any).dataLayer !== 'undefined' ) {
            (window as any).dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': 'EUR',
                    'add': {
                        'products': [{
                            'name': payment.title,
                            'id': payment.id,
                            'price': (payment.amount) / 100,
                            'brand': 'Club Menu Saludable',
                            'category': 'Suscripción',
                            'variant': variant,
                            'quantity': 1
                        }]
                    }
                }
            });
        }
    }

    public GTMFirstStepEvent(payment, variant, coupon = null ) {
        if ( typeof (window as any).dataLayer !== 'undefined' ) {
            (window as any).dataLayer.push({
                'event': 'checkout',
                'ecommerce': {
                    'checkout': {
                        'actionField': {
                            'step': 1,
                            'option': coupon,
                        },
                        'products': [{
                            'name': payment.title,
                            'id': payment.id,
                            'price': (payment.amount) / 100,
                            'brand': 'Club Menu Saludable',
                            'category': 'Suscripción',
                            'variant': variant,
                            'quantity': 1
                        }]
                    }
                },
            });
        }
    }

    public GTMSecondStepEvent(payment, variant) {
        if ( typeof (window as any).dataLayer !== 'undefined' ) {
            (window as any).dataLayer.push({
                'event': 'checkout',
                'ecommerce': {
                    'checkout': {
                        'actionField': {
                            'step': 2,
                            'option': '',
                        },
                        'products': [{
                            'name': payment.title,
                            'id': payment.id,
                            'price': (payment.amount) / 100,
                            'brand': 'Club Menu Saludable',
                            'category': 'Suscripción',
                            'variant': variant,
                            'quantity': 1
                        }]
                    }
                },
            });
        }
    }

    public GTMPurchaseEvent(payment, variant, coupon, paymentId) {
        if ( typeof (window as any).dataLayer !== 'undefined' ) {
            (window as any).dataLayer.push({
                'ecommerce': {
                    'purchase': {
                        'actionField': {
                            // VenuSanz Transaction ID. Required for purchases and refunds.
                            'id': paymentId,
                            'affiliation': 'Club Menu Saludable Register',
                            // Total transaction value (incl. tax and shipping)
                            'revenue': (payment.amount) / 100,
                            'tax': '0',
                            'shipping': '',
                            'coupon': coupon
                        },
                        'products': [{
                            'name': payment.title,
                            'id': payment.id,
                            'price': (payment.amount) / 100,
                            'brand': 'Club Menu Saludable',
                            'category': 'Suscripción',
                            'variant': variant,
                            'quantity': 1,
                            'coupon': coupon
                        }]
                    }
                }
            });
        }
    }

    public FBTrackEvent(properties: string) {
        (window as any).fbq('track', properties);
    }

    public FBPurchase( val: number, curr: string, planId: string ) {
        (window as any).fbq('track', 'Purchase', { value: val, currency: curr, content_ids: planId });
    }

    public PinterestTrackEvent(properties: string) {
        (window as any).pintrk('track', properties);
    }

    public PinterestCheckout( val: number, quantity: number, curr: string ) {
        (window as any).pintrk('track', 'checkout', { value: val, order_quantity: quantity, currency: curr });
    }

}
