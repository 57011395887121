import { Injectable } from '@angular/core';
import { StripeApiService } from '@api/stripe-api.service';
import { map } from 'rxjs/operators';
import { Subscription } from '@core/models/subscriptionStatus';


@Injectable({ providedIn: 'root' })
export class StripeService {

    private stripe: stripe.Stripe;

    private lastErrorMessage: string;

    constructor(
        private stripeApiService: StripeApiService
    ) { }

    public initializeStripe(apikey: string) {
        this.stripe = (window as any).Stripe(apikey);
    }

    public getStripeProductIdFromUrl() {
      const val = new URLSearchParams(window.location.search).get('product_id');
      return (val === undefined || val == null || val.length <= 0) ? '' : val;
    }

    public getStripe(): stripe.Stripe {
        return this.stripe;
    }

    public getIntent(): Promise<any> {
        return this.stripeApiService.getIntent().toPromise();
    }

    public getPaymentStatus(): Promise<Subscription> {
        return this.stripeApiService.getPaymentStatus().pipe(
            map(subscription => {
                if (subscription.metadata) {
                    subscription.metadata.visible = subscription.metadata.visible && (/true/i).test(subscription.metadata.visible as string);
                }
                return subscription;
            })
        ).toPromise();
    }

    public getLastStripeMessage() {
      const message = this.lastErrorMessage;
      this.lastErrorMessage = null;
      return message;
    }

    public async pauseResumeSubscription(behaviour: string, resumes_at?: string) {
        const response = await this.stripeApiService.pauseResumeSubscription(behaviour, resumes_at).toPromise();
        return await this.handleSubscriptionResponse(response);
    }

    public async acceptCancellationGift(giftId: number) {
        const response = this.stripeApiService.acceptCancellationGift(giftId).toPromise();
        await this.handleSubscriptionResponse(response);
        return response;
    }

    public sendCancellationReason(stripeSubscriptionId: string, reasonKey: string, comment?: string) {
        return this.stripeApiService.sendCancellationReason(stripeSubscriptionId, reasonKey, comment).toPromise();
    }

    public async handleSubscriptionResponse(subscriptionResponse) {

      const allowedStatus = ['active', 'trialing'];
      if ( allowedStatus.includes(subscriptionResponse.subscription_status) &&
        (subscriptionResponse.payment_intent == null || subscriptionResponse.payment_intent.status === 'succeeded' )) {
        return true;
      } else {
        let stripeResponse;
        switch (subscriptionResponse.payment_intent.status) {
          case 'requires_confirmation':
            stripeResponse = await this.stripe.confirmCardPayment(
              subscriptionResponse.payment_intent.client_secret
            );
            break;
          case 'requires_action':
            stripeResponse = await this.stripe.confirmCardPayment( // the same as requires_confirmation
              subscriptionResponse.payment_intent.client_secret
            );
            break;
        }
        if (stripeResponse.paymentIntent && stripeResponse.paymentIntent.status === 'succeeded') {
          return true;
        } else {
          this.lastErrorMessage = stripeResponse.paymentIntent.last_payment_error.message;
          return false;
        }
      }
    }

}
