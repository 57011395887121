import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppShellAuxiliarButtonsHostDirective } from './shell-auxiliar-buttons';
import { DeferLoadDirective } from './defer-load';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [AppShellAuxiliarButtonsHostDirective, DeferLoadDirective],
    exports: [AppShellAuxiliarButtonsHostDirective, DeferLoadDirective]
})
export class DirectivesModule { }