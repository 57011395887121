// Basic
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MenuOption } from '@core/models/menuOption';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-mobile-submenu-dialog',
  templateUrl: 'mobile-submenu-dialog.component.html',
  styleUrls: ['mobile-submenu-dialog.component.scss']
})
export class MobileSubmenuDialogComponent {

  public submenuOptions: MenuOption[];

  constructor(
    public dialogRef: MatDialogRef<MobileSubmenuDialogComponent>,
    private router: Router,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.submenuOptions = [
      {
        path: ['/preferences'],
        text: 'Preferencias',
        disabled: false
      },
      {
        path: ['/subscription'],
        text: 'Suscripción',
        disabled: false
      },
      {
        externalLink: 'https://ayuda.venusanzchef.com/',
        text: '¿Necesitas ayuda?',
        disabled: false
      },
      {
        externalLink: 'https://clubmenusaludable.com/',
        text: 'Club Menú Saludable',
        disabled: false
      }
    ];
  }

  public doLogout() {
    this.authService.doLogout();
    this.closeDialog();
  }

  public closeDialog(option?: MenuOption) {
    if (option) {
      if (option.path) {
        this.router.navigate(option.path);
      } else {
        window.location.href = option.externalLink;
      }
    }
    this.dialogRef.close();
  }
}
