import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwapRecipeWeeklyDialogComponent } from './swap-recipe-weekly-dialog.component';
import { MaterialModule } from '@app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuxiliarSearchOptionsShellModule } from '@components/auxiliar-search-options-shell/auxiliar-search-options-shell.module';
import { NgxPaginationModule } from 'ngx-pagination';
import {MatSliderModule} from "@angular/material/slider";
import {NoContentModule} from "@components/no-content/no-content.module";


@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        AuxiliarSearchOptionsShellModule,
        NgxPaginationModule,
        MatSliderModule,
        NoContentModule
    ],
  declarations: [SwapRecipeWeeklyDialogComponent],
    exports: [SwapRecipeWeeklyDialogComponent],
    entryComponents: [SwapRecipeWeeklyDialogComponent]
})
export class SwapRecipeWeeklyDialogModule { }
