// Basic
import { Component, Inject, OnInit, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { CUSTOM_BREAKPOINTS_KEYS } from '@core/constants/customBreakpoints.constants';
import { UserService } from '@services/user.service';
import { LoadingService } from '@services/loading.service';
import { Logger } from '@services/logger.service';
import {environment} from '@env';

const log = new Logger('onboarding-dialog.component');

@Component({
  selector: 'app-onboarding-dialog',
  templateUrl: 'onboarding-dialog.component.html',
  styleUrls: ['onboarding-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OnboardingDialogComponent implements OnInit {

  @ViewChild(MatStepper, { static: false })
  public stepper: MatStepper;

  private isMobile: boolean;
  private mediaSubscription: Subscription;

  public onBoardingStarted: boolean;
  public onBoardingCompleted: boolean;

  private isVegan: boolean;
  private menuNumber: number;
  private allergenIndexSelected: number;

  constructor(
    public dialogRef: MatDialogRef<OnboardingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mediaObserver: MediaObserver,
    private userService: UserService,
    private loadingService: LoadingService
  ) {
    this.onBoardingStarted = false;
    this.onBoardingCompleted = false;
  }

  public ngOnInit() {
    this.mediaSubscription = this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      if (changes) {
        this.isMobile = !!changes.filter((change) => {
          return change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.LT_SM ||
            change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.SM || change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.XS;
        }).length;
        if (!this.isMobile) {
          this.dialogRef.updateSize('800px', '700px');
        } else {
          this.dialogRef.updateSize('100vw', '100vh');
        }
      }
    });
  }

  public onSelectedDiet(event) {
    this.isVegan = event;
  }

  public onSelectedMenuNumber(event) {
    this.menuNumber = event;
  }

  public onSelectedAllergen(event) {
    this.allergenIndexSelected = event;
  }

  public startOnboarding() {
    this.onBoardingStarted = true;
  }

  public async completedOnboarding() {
    const params = [
      {
        key: 'allergen_id',
        value: this.allergenIndexSelected
      },
      {
        key: 'num_diners',
        value: this.menuNumber
      },
      {
        key: 'vegan',
        value: this.isVegan
      },
      {
        key: 'profile_completed',
        value: true
      }
    ];

    try {
      this.loadingService.openLoading(false);
      await this.userService.updateUser(params);
      this.closeDialog();
    } catch (error) {
      log.debug(error);
    }

  }


  public onNextStep() {
    if (this.stepper.selectedIndex === 2) {
      this.onBoardingCompleted = true;
    } else {
      this.stepper.next();
    }
  }

  private closeDialog() {
    this.mediaSubscription.unsubscribe();
    this.dialogRef.close();
  }
}
