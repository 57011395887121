import { Injectable } from "@angular/core";
import { PaymentPlanApiService } from '@api/payment-plan-api.service';
import { Payment } from '@core/models/payment';
import { map } from 'rxjs/operators';
import { Club } from '@core/models/club';
import {CancellationGift} from "@models/cancellationGift";
import {ProductsConfig} from "@models/productsConfig";

@Injectable({ providedIn: 'root' })
export class PaymentPlanService {

    constructor(
        private paymentPlanApiService: PaymentPlanApiService
    ) { }

    public getProductsConfig(stripeProductId: string = ''): Promise<ProductsConfig> {
        return this.paymentPlanApiService.getProductsConfig(stripeProductId).toPromise();
    }

    public getPaymentPlans(stripeProductId: string = ''): Promise<Payment[]> {
        return this.paymentPlanApiService.getPaymentPlans(stripeProductId).pipe(
            map((paymentPlans: Payment[]) => {
                paymentPlans = paymentPlans.filter(plan => {
                    return !!plan.metadata.visible && (/true/i).test(plan.metadata.visible as string);
                });
                return paymentPlans.sort((a, b) => a.amount - b.amount);
            })
        ).toPromise();
    }

    public getTestimonials(): Promise<any> {
        return this.paymentPlanApiService.getTestimonials().toPromise();
    }

    public getClubStatus(): Promise<Club> {
        return this.paymentPlanApiService.getClubStatus().toPromise();
    }

    public getCancellationGifts(planRole: string): Promise<CancellationGift[]> {
        return this.paymentPlanApiService.getCancellationGifts(planRole).toPromise();
    }

}
