// Core components
import { Component, OnInit, OnDestroy } from '@angular/core';

// Constants
import { Opinion } from '@core/models/opinion';
import { OPINION_MOCK } from '@assets/dbMock/opinion-mock';
import { Footer } from '@core/models/footer';
import { Logger } from '@services/logger.service';
import { interval, Subscription } from 'rxjs';
import { PaymentPlanService } from '@services/payment-plan.service';
import { Club } from '@core/models/club';
import { LoadingService } from '@services/loading.service';

const log = new Logger('welcome.component.ts');

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})

export class WelcomeComponent implements OnInit, OnDestroy {

    public showDishArgumentImage: boolean;
    public showDishMenuImage: boolean;
    public showAvatarOpinion: boolean;
    public showCheckImage: boolean;
    public showRecipeSM: boolean;
    public showRecipeSX: boolean;
    public showVenuImage: boolean;
    public showSpecialist: boolean;
    public showIngredientsImage: boolean;
    public showRecipeMenuImage: boolean;
    public showSpagettiImage: boolean;
    public showDishJoinImage: boolean;
    public showicsBatch: boolean;
    public clubStatus: Club;

    public paymentPlans: string[];
    public opinions: Opinion[];
    public opinionIndex: number;
    public footerOptions: Footer[];
    public paymentSelectedIndex: number;

    private interval$: Subscription;

    constructor(
        private paymentPlanService: PaymentPlanService,
        private loadingService: LoadingService
    ) {

        this.footerOptions = [
            {
                text: 'Preguntas frecuentes',
                externalLink: 'https://ayuda.venusanzchef.com/'
            },
            {
                text: 'Contacto',
                externalLink: 'https://ayuda.venusanzchef.com/'
            },
            {
                routerLink: ['/sale-conditions'],
                text: 'Condiciones de Venta'
            },
            {
                routerLink: ['/privacy-policy'],
                text: 'Política de privacidad'
            },
            {
                routerLink: ['/cookies-policy'],
                text: 'Política de cookies'
            },
            {
                routerLink: ['/legal-warning'],
                text: 'Aviso legal'
            }
        ];

    };

    public async ngOnInit() {
        this.opinionIndex = 0;

        this.opinions = OPINION_MOCK;

        this.interval$ = interval(10000).subscribe(() => {
            this.nextOpinion();
        });

        try {
            this.loadingService.openLoading(false);
            this.clubStatus = await this.paymentPlanService.getClubStatus();
        } catch(error) {
            log.debug(error);
        } finally {
            this.loadingService.closeLoading();
        }
    }

    public ngOnDestroy() {
        this.interval$.unsubscribe();
    }

    public changePaymentSubscription(payment) {
        this.paymentSelectedIndex = payment.index;
    }

    private nextOpinion = () => {
        if (++this.opinionIndex > this.opinions.length - 1) {
            this.opinionIndex = 0;
        }
    }

    public setIndex(index: number) {
        this.opinionIndex = index;
    }

    public clubClosed() {
        window.open(this.clubStatus.WAITING_LIST_URL, '_blank')
    }
}
