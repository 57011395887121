// Core
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import {ActivatedRoute} from "@angular/router";

//Environment
import { environment } from '@env';

// Models
import { ProductsConfig } from '@core/models/productsConfig';

// Services
import { Logger } from '@services/logger.service';
import { Subscription } from 'rxjs';
import { CookieBannerService } from '@services/cookies-banner.service';
import { LoadingService } from '@services/loading.service';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { DateServices } from '@services/date.service';
import { StripeService } from '@services/stripe.service';
import { ColorSchemeService } from '@services/color-scheme.service';
import { PaymentPlanService } from '@services/payment-plan.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  private popupOpenSubscription: Subscription;

  public isLoading: boolean;
  public isSoftLoader: boolean;
  private stripe: stripe.Stripe;

  /**
   * Use this component to setup the application.
   */

  constructor(
    private cookieBannerService: CookieBannerService,
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private userService: UserService,
    private dateService: DateServices,
    private stripeService: StripeService,
    private activatedRoute: ActivatedRoute,
    private paymentPlanService: PaymentPlanService,
    public colorSchemeService: ColorSchemeService
  ) {
    this.colorSchemeService.load();
  }

  public async ngOnInit() {

    if (environment.production) {
      Logger.enableProductionMode();
    }

    // Cookie banner
    this.cookieBannerService.init();

    // Stripe
    if ( this.stripeService.getStripe() === undefined ) {
      const stripeProductId = this.stripeService.getStripeProductIdFromUrl();
      const productsConfig = await this.paymentPlanService.getProductsConfig(stripeProductId);
      this.stripeService.initializeStripe(productsConfig.publishable_key);
    }

    //Date moment language
    moment.locale('es');

    //Auth user.
    this.authService.isAuthenticated().subscribe(async (isAuthenticated: boolean) => {
      if (isAuthenticated) {
        this.dateService.init();
        await this.userService.setCurrentUser();
      }
    })
  }

  public ngAfterViewInit() {
    this.loadingService.loadingObserver().subscribe((isLoading) => {
      this.isLoading = isLoading;
      this.changeDetectorRef.detectChanges();
    });
    this.loadingService.isSoftLoaderObserver().subscribe((isSoftLoader) => {
      this.isSoftLoader = isSoftLoader;
      this.changeDetectorRef.detectChanges();
    });
  }

  public ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
  }
}
