// Basic
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { CUSTOM_BREAKPOINTS_KEYS } from '@core/constants/customBreakpoints.constants';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { StripeService } from '@services/stripe.service';
import { Logger } from '@services/logger.service';
import { UserService } from '@services/user.service';
import { ProfileDatabaseService } from '@db/profile-database.service';
import { DATABASE_KEYS } from '@core/constants/storage.constants';
import { Subscription as UserSubscription } from '@core/models/subscriptionStatus';
import * as moment from 'moment';

const log = new Logger('paused-plan-dialog');

@Component({
  selector: 'app-paused-plan-dialog',
  templateUrl: 'paused-plan-dialog.component.html',
  styleUrls: ['paused-plan-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PausedPlanDialogComponent implements OnInit {


  private isMobile: boolean;
  private mediaSubscription: Subscription;

  public timestamp: string;

  constructor(
    public dialogRef: MatDialogRef<PausedPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mediaObserver: MediaObserver,
    private authService: AuthService,
    private router: Router,
    private stripeService: StripeService,
    private userService: UserService,
    private profileDatabaseService: ProfileDatabaseService
  ) { }

  public async ngOnInit() {
    this.mediaSubscription = this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      if (changes) {
        this.isMobile = !!changes.filter((change) => {
          return change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.LT_SM ||
            change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.SM || change.mqAlias === CUSTOM_BREAKPOINTS_KEYS.XS;
        }).length;
        if (!this.isMobile) {
          this.dialogRef.updateSize('800px', '700px');
        } else {
          this.dialogRef.updateSize('100vw', '100vh');
        }
      }
    });
    try {
      const subscription: UserSubscription = await this.profileDatabaseService.get(DATABASE_KEYS.subscription).toPromise();
      this.timestamp = moment(new Date(subscription.resumes_at * 1000)).format('DD/MM/YYYY');
    } catch (error) {
      log.debug(error);
    }
  }

  public async goToReactivatePlan() {
    try {
      await this.stripeService.pauseResumeSubscription('RESUME');
      await this.userService.setCurrentUser()
      this.router.navigate(['/weekly-menu']);
      this.close();
    } catch (error) {
      log.debug(error);
    }
  }

  public closeDialog() {
    this.close();
  }

  private close() {
    this.mediaSubscription.unsubscribe();
    this.dialogRef.close();
  }
}
