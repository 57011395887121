import { Injectable } from "@angular/core";
import { MenusApiService } from '@api/menus-api.service';
import { map } from 'rxjs/operators';
import { DailyMenu } from '@core/models/dailyMenu';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MenusService {

    constructor(
        private menusApiService: MenusApiService,
        private _sanitizer: DomSanitizer
    ) { }

    public getMenu(date: string): Promise<DailyMenu[]> {
        return this.menusApiService.getMenu(date).pipe(
            map((menus) => {
                if (menus && menus.length) {
                    return menus.map(menu => {
                        menu.day = menu.day * 1000;
                        this.addImage(menu);
                        return menu;
                    }).sort((a, b) => a.day - b.day);
                }
                return menus;

            })
        ).toPromise();
    }


    private addImage(menu) {
        const keys = Object.keys(menu).filter(menu => (menu !== 'day' && menu !== 'menu_id'));
        for (const key of keys) {
            if (menu[key]) {
                if (menu[key].image) {
                    menu[key].image = this._sanitizer.bypassSecurityTrustStyle(`url(${menu[key].image})`);
                } else {
                    menu[key].image = this._sanitizer.bypassSecurityTrustStyle(`url('assets/images/png/recipe-placeholder.png')`);
                }
            }
        }
    }
}