import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NoContentComponent } from './no-content.component';

// Modules
import { MaterialModule } from '@app/material.module';


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule
  ],
  declarations: [NoContentComponent],
  exports: [NoContentComponent]
})

export class NoContentModule { }
