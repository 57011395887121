// Basic
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

// Modules
import { MaterialModule } from '@app/material.module';

// Components
import { OnboardingDialogComponent } from './onboarding-dialog.component';
import { SelectorPeopleMenuModule } from '@components/selector-people-menu/selector-people-menu.module';
import { SelectorAllergensModule } from '@components/selector-allergens/selector-allergens.module';
import { SelectorDietsModule } from '@components/selector-diets/selector-diets.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    SelectorPeopleMenuModule,
    SelectorAllergensModule,
    SelectorDietsModule
  ],
  declarations: [OnboardingDialogComponent],
  exports: [OnboardingDialogComponent],
  entryComponents: [OnboardingDialogComponent]
})
export class OnboardingDialogModule { }
