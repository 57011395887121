import { Category } from '@models/category';

export interface Ingredient {
    id: number;
    allergen_id: number;
    description: string;
    category: Category[];
    include_in_shopping_list: number;
    name: string;
    qty?: number;
    sub_ingredients: Ingredient[];
    type: Ingredient.type;
    ingredient_type: Ingredient.type;
    units: string;
    bought?: boolean;
    menuId?: number;
    details: Ingredient.details[];
    group: string;
    order: number;
    substitute: string;
}


export namespace Ingredient {
    export enum type {
        SIMPLE = 'SIMPLE',
        COMPOUND = 'COMPOUND'
    }

    export interface details {
        qty: number;
        units: string;
    }
}

