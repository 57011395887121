export const UNITS_OF_MEASURE = {
     u: {
          label: 'unidad(es)',
          unit: 'unidad(es)',
          isCountable: true
     },
     g: {
          label: 'gramos',
          unit: 'gr',
          isCountable: true
     },
     gr: {
          label: 'gramos',
          unit: 'gr',
          isCountable: true
     },
     ml: {
          label: 'mililitros',
          unit: 'ml',
          isCountable: true
     },
     cm: {
          label: 'centimetros',
          unit: 'cm',
          isCountable: true
     },
     cuchara_sopera: {
          label: 'cucharada sopera',
          unit: 'c.s.',
          isCountable: true
     },
     cuchara_postre: {
          label: 'cucharada de postre',
          unit: 'c.p.',
          isCountable: true
     },
     hoja: {
          label: 'hoja(s)',
          unit: 'hoja(s)',
          isCountable: true
     },
     manojo: {
          label: 'manojo',
          unit: 'manojo',
          isCountable: true
     },
     ramillete: {
          label: 'ramillete(s)',
          unit: 'ramillete(s)',
          isCountable: true
     },
     punado: {
          label: 'puñado(s)',
          unit: 'puñado(s)',
          isCountable: true
     },
     diente: {
          label: 'diente(s)',
          unit: 'diente(s)',
          isCountable: true
     },
     rama: {
          label: 'rama(s)',
          unit: 'rama(s)',
          isCountable: true
     },
     trozo: {
          label: 'trozo(s)',
          unit: 'trozo(s)',
          isCountable: false
     },
     cabeza: {
          label: 'cabeza(s)',
          unit: 'cabeza(s)',
          isCountable: true
     },
     rebanada: {
          label: 'rebanada(s)',
          unit: 'rebanada(s)',
          isCountable: true
     },
     vaina: {
          label: 'vaina(s)',
          unit: 'vaina(s)',
          isCountable: true
     },
     rodaja: {
          label: 'rodaja(s)',
          unit: 'rodaja(s)',
          isCountable: true
     },
     pizca: {
          label: 'pizca(s)',
          unit: 'pizca(s)',
          isCountable: false
     },
     cantidad_necesaria: {
          label: 'cantidad necesaria',
          unit: 'c.n.',
          isCountable: false
     },
     al_gusto: {
          label: 'al gusto',
          unit: 'al gusto',
          isCountable: false
     },
     taza: {
          label: 'taza',
          unit: 'taza',
          isCountable: true
     }
};
